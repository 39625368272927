.customer-holder {
    padding: 0 10px;
}

.customer-panel {
    margin-bottom: 10px;

    .btn-col {
        width: 115px;
        padding: 0 5px 0 0;
        float: left;
    }

    .title-col {
        overflow: hidden;
        background: #F6F6F6;
        padding: 10px 10px 20px;

        h2 {
            font-size: 20px;
            margin: 0 0 5px;
        }
    }

    .btn {
        position: relative;
        width: 100%;
        padding: 12px 20px;
        font-size: 14px;
        line-height: 16px;
        height: 58px;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 9px;
            width: 4px;
            height: 14px;
            background: #cee8a5;
        }

        &:after {
            width: 14px;
            height: 4px;
            right: 4px;
        }
    }
}

.customers-wrap {

    .customer-item {
        position: relative;
        padding: 10px 10px 10px 70px;
        border-top: 1px solid #D1D1D1;
        min-height: 85px;

        &:last-child {
            border-bottom: 1px solid #D1D1D1;
        }

        h4 {
            font-size: 18px;
            margin: 0 0 15px;
        }

        ul {
            margin: 0;
            list-style: none;
            padding: 0;
        }

        li {
            margin-bottom: 3px;

            a {
                display: inline-block;
                vertical-align: top;
                padding: 4px 22px 4px 4px;
                position: relative;
            }

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
        }
    }




    .btn {
        font-size: 10px;
        width: 60px;
        position: absolute;
        top: 10px;
        left: 0;

        &:before {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 3px;
            background: url(../../../assets/img/sprites.png) no-repeat;
            background-position: -3px -413px;
        }

        span {
            display: block;
        }
    }
}