.new-document-holder {

    .debug-button-container {
        position: fixed;
        top: 0;
        left: 0;
        padding: 6px;
        background: white;
        border: 1px solid silver;
        z-index: 998;
    }

    .new-document-title {
        margin-bottom: 10px;
        text-align: center;

        h1 {
            font-size: 24px;
            margin: 0;
            text-align: center;
        }
    }

    a {
        outline: none;
    }
    .discount-block,
    .extras-block {
        border: 1px solid #dadada;

        margin-top: 4px;
        padding-top: 4px;
        .radio-wrap {
            display: inline-block;
        }
        .form-control {
            border-top: 1px solid #dadada;
            border-bottom: 1px solid #dadada;
        }
        .btn-row {
            padding: 6px;
            text-align: center;
        }
    }

    .item-row,
    .payment-row {
        border-top: 1px solid silver;
        border-bottom: 1px solid silver;
        border-collapse: collapse;
        margin-bottom: 5px;
        .btn-wrap {

            .btn.remove-button {
                height: 20px;
                padding: 0;
                width: 95px !important;
            }
        }
    }
    .new-document-nav {

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: hidden;

            li {
                float: left;
                width: 33.33%;
                height: 40px;
                color: #fff;
                background: #6eb5eb;
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 1;
                position: relative;

                &.active {
                    background: #0d83dd;

                    &:after {
                        background: #0d83dd;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background: #6eb5eb;
                    top: 4px;
                    right: 100%;
                    transform: translateX(50%) rotate(-45deg);
                    border-top: 2px solid #fff;
                    border-left: 2px solid #fff;
                }

                a {
                    display: block;
                    padding: 13px 40px 13px 20px;
                    text-decoration: none;
                    color: inherit;
                    position: relative;
                    z-index: 3;
                    outline: none;
                }
            }
        }
    }
}

.document-type-links {
    padding-top: 20px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            color: #000;
            margin-bottom: 7px;
        }

        a {
            display: block;
            color: inherit;
            padding: 18px 40px;
            background: #f6f6f6;
            position: relative;
            text-decoration: none;
            outline: none;
            font-size: 14px;
            line-height: 18px;

            &:after {
                content: '';
                position: absolute;
                border-right: 8px solid #0d83dd;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);

            }
        }

        .ico-holder {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
        }
    }
}

.invoice-number-box {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.1;
    position: relative;
    padding: 20px 10px 20px 80px;
}

.document-row-title {
    padding: 15px 10px;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    background: #f6f6f6;

    &.blue-bg-title {
        background: #E6F4FC;
    }

    h3 {
        font-size: 20px;
        margin: 0;
    }
}

.new-document-form-row {
    font-size: 16px;

    + .new-document-form-row {
        border-top: 1px solid #dadada;
    }
    .wrap_over_input{
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
    }
    .wrap_over_input .date-picker {
        width: auto !important;
    }
    .wrap_over_input label {
        padding: 10px;
        text-align: right;
    }
    &.two-columns {
        display: flex;
        flex-wrap: wrap;

        .form-group {
            width: 50%;

            + .form-group {
                border-right: 1px solid #dadada;
            }
        }
    }

    &.btn-holder {
        padding: 10px;
        text-align: center;
        overflow: hidden;
        font-size: 0;
        line-height: 0;

        &.customer-btns {

            .btn-wrap {

                .btn {
                    height: 35px;
                }
            }
        }

        .btn-wrap {
            width: 50%;
            padding: 0 5px;
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            line-height: 1.1;

            .btn {
                display: inline-block;
                vertical-align: top;
                height: 55px;
                width: 110px;
            }

            &:only-child {
                width: 100%;

                .btn {
                    width: 190px;
                }
            }

            .btn-add-mail {
                position: relative;
                padding-left: 30px;
                padding-right: 30px;
                width: 190px;

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 23px;
                    width: 4px;
                    height: 14px;
                    background: #cee8a5;
                }

                &:after {
                    width: 14px;
                    height: 4px;
                    right: 18px;
                }
            }
        }
    }

    .radio-row,
    .checkbox-row {
        padding: 10px;
    }

    .radio-wrap,
    .checkbox-wrap {

        .custom-label {
            overflow: hidden;
            padding-right: 7px;
            width: calc(~'100% - 16px');
            display: block;
        }

        .custom-checkbox,
        .custom-radio {
            float: right;
        }

        .custom-checkbox {
            background: #F6F6F6;
            border-color: #CBCBCB;
        }

        input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            height: 1px;
            width: 1px;
            opacity: 0;
            left: -9999px;
            top: -9999px;

        }
    }

    .select-holder {

        &:before {
            border-top-color: #000;
        }
    }
    input.ng-invalid.ng-touched {
        border-color: #ed1c24!important;
    }
    .form-control {
        color: #000;
        font-size: 16px;
        width: 100%;
        display: block;
        height: 100%;
        min-height: 38px;
        line-height: 20px;
        padding: 9px 10px;
        border-color: transparent;
        background: transparent;




        &[disabled] {
            background: rgba(230, 230, 230, 0.5);
            color: gray;
        }
        .placeholder({ color: #000; })
    }

    textarea.form-control {
        height: 80px;
        resize: vertical;
    }

    .three-column-summary-table {
        width: 100%;
        td {
            padding: 5px 10px;

            width: 45%;

            text-align: right;

            &:last-child {
                width: 10%;
            }
        }
    }
    .payments-table {
        width: 100%;

        tfoot {
        }

        td.remove-button-column{
            padding: 0 0 0 5px;
            text-align: left;
        }

        td {
            padding: 5px 10px;
            text-align: right;

            &:last-child {
                width: 1px;
                white-space: nowrap;
            }
        }
    }

    .summary-table {
        width: 100%;

        tfoot {
        }

        td {
            padding: 5px 10px;
            text-align: right;

            &:last-child {
                width: 1px;
                white-space: nowrap;
            }
        }
        tr:last-of-type {
            font-weight: bold;
            font-size: 16px;
        }
    }
    .internal-comment-wrapper {
        padding: 9px 10px;
        & > * {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.added-email {
    padding: 10px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin-bottom: 10px;
        .close-btn {
            display: inline-block;
            float: left;
        }

    }
}

.select-payment-type-buttons {

    padding-top: 20px;

    .new-document-form-row.btn-holder {

        border: none;
        padding:3px;

        .btn-wrap .btn.btn-small {
            height: 28px;
        }
    }
}

.btn-docType{
    display: inline-block;
    position: relative;
    height:140px;
    width: 140px;
    font-size: 22px;

    background-size: 160px;
    background-position-x: -10px;
    background-position-y: -7px;
    border: 1px solid silver;
    border-radius: 12px;

    &.tax_invoice_receipt{
        background-image: url(../../img/tax_invoice_receipt.png);
        background-position-y: -12px;
    }
    &.tax_invoice{
        background-image: url(../../img/tax_invoice.png);
        background-position-y: -11px;
    }
    &.receipt{
        background-image: url(../../img/receipt.png);
        background-position-y: -8px;
    }
    &.order{
        background-image: url(../../img/order.png);
    }
    &.delivery_note{
        background-image: url(../../img/delivery_note.png);
    }
    &.perform{
        background-image: url(../../img/perfoma.png);
        background-position-y: -9px;

    }
    &.price_quote{
        background-image: url(../../img/price_quote.png);
        background-position-y: -11px;
    }
    &.receipt_credit{
        background-image: url(../../img/credit_invoice.png);
        background-position-y: -13px;
    }
    &.payment_request{
        background-image: url(../../img/payment_request.png);
        background-position-y: -11px;
    }
    &.return_form{
        background-image: url(../../img/return_note.png);
        background-position-y: -11px;
    }
    &.balance_adjustment{
        background-image: url(../../img/balance.png);
        background-position-y: -11px;
    }
    .button-text{
        border-radius: 9px;
        width: 107px;
        height: 36px;
        color: #fff;
        position: absolute;

        display: inline-block;
        font-size: 13px;
        top: 77px;
        left: 15px;
        white-space: nowrap;

        >span{
            white-space: normal;
            display: inline-block;
            vertical-align: middle;
        }
        &::after{
            content: '';
            height: 30px;
            width: 1px;
            display: inline-block;
            vertical-align: middle;
        }

    }
}

.row-title-box{
  padding: 9px 10px;
  font-weight: bold;
}
.credit-type-section{
    position: relative;
    background-image: url("../../img/credit_docs_group.png");
    background-size: 310px;
    width:290px;
    height: 122px;
    border: 1px solid silver;
    border-radius: 12px;
    display: inline-block;
    background-position-x: -10px;
    background-position-y: -7px;
    vertical-align: top;

    .title-label{
        position: absolute;
        top: 72px;
        right: 13px;
        height: 27px;
        width: 95px;
        margin-top: 3px;
        color: white;
    }

    .button-wrapper{
        position: absolute;
        width: 146px;
        height: 101px;
        top: 8px;
        left: 18px;
        button{
            border: none;
            width: 100%;
            height: 27px;
            background: none;
            color: #135289;
            font-size: 10px;
            margin-bottom: 10px;
        }
    }
}
