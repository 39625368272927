.help-title {
    background: #1985d8;
    padding: 15px 15px;
    color: #fff;
    margin-bottom: 12px;
    text-align: center;

    h1 {
        font-size: 35px;
        margin: 0;
        padding: 0 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;

        img {
            position: absolute;
            bottom: 20px;
            left: 100%;
        }
    }
}


/* help accordion styles*/
.help-section {
    color: #000;
    font-size: 16px;
    line-height: 1.2;

    h4 {
        margin: 0;
        font-size: 18px;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .panel {
        margin-bottom: 3px;

        &.collapse-active {

            .collapse {
                display: block;
            }

            .panel-heading {

                a {
                    &:before {
                        transform: translateY(-50%) rotate(-90deg);
                    }
                }
            }
        }
    }

    .panel-heading {
        padding: 0;

        a {
            display: block;
            padding: 10px 10px 10px 35px;
            background: #F6F6F6;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                //transform: translateY(-50%) rotate(-90deg);
                border-bottom: 7px solid transparent;
                border-top: 7px solid transparent;
                border-right: 7px solid #1985d8;
            }
        }
    }

    .panel-body {
        padding: 15px 10px;
    }

    p {
        margin: 0;
    }

    .collapse {
        display: none;
    }

    .play-video{
        background-color: #F6F6F6;
        display: flex;
        width: 100%;
        height: 100%;
        border: 1px solid #CCC;
        border-radius: 2px;
        background: #F6F6F6;
        overflow: hidden;
        position: relative;

        >img{
            width: 100%;
            height: 100%;
        }
    }
    .play-video:after{
        content: '';
        display: block;
        position: absolute;
        z-index: 100;
        width: 80px;
        height: 80px;
        background: #DADADA url(../../img/ico_play.png);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: -40px 0 0 -40px;
    }
}
