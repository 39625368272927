.customer-details-header{

    .has-error {
        input.name-input{
            border-bottom: 1px solid #ed1c24;
        }
    }


    input.name-input{
        background: none;
        border: none;
        font-weight: bold;
        font-size: 22px;
        width: 215px;
    }

    .close-btn{
        background-image: url("../../../../assets/img/mobile/ico_close.png");

    }
}

.edit-customer-form {


    .message-holder{
        padding-right: 5px;
    }
    .form-group {
        min-height: 30px;
        border-bottom: 1px solid #ccc;
    }

    .input-holder  {
        overflow: hidden;
    }

    .label-holder {
        float: right;
        font-size: 14px;
        line-height: 20px;
        height: 36px;
        padding: 8px 5px;
    }

    .form-control {
        width: 100%;
        display: block;
        background: none;
        border: none;
        height: 36px;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 5px;
    }

    .add-extra-item {

/*        li {
            padding-left: 30px;
            padding-right: 30px;
        }*/
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            display: block;
            min-height: 30px;
        }

        a {
            color: #0000ff;
        }

        table.wrapper-table{
            width: 100%;
            height:32px;
            tr{
                td.close-column,
                td.add-column{
                    width: 18px;
                }
                td{
                    min-width: 18px;
                    vertical-align: middle;
                }
            }
        }

    }
}

