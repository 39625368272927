@import "partials/customer-details-popup.less";

.edit-customer-title {
    background: #f6f6f6;

    h4 {
        font-size: 18px;
        margin: 0;
    }

    .btn {
        font-size: 16px;
        padding: 2px 4px;
        width: 60px;
        vertical-align: middle;
    }

    .title-content {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px 0 30px;
    }

    .title-row {
        position: relative;
        padding: 10px;

        &.title-info {
            text-align: left;
            font-size: 16px;
            line-height: 1.1;

            strong {
                display: inline-block;
                vertical-align: bottom;

                &:after {
                    content: 'NIS ';
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 2px;

                }
            }

            span {
                font-weight: bold;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }

    .btn-info {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.edit-customer-panel {
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 3px 0 -3px 0;
    z-index: 99;
    background: #85c51f;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 0;
        line-height: 0;
        text-align: center;
    }

    li {
        width: 20%;
        padding: 0 5px;
        font-size: 13px;
        line-height: 1;
        display: inline-block;
        vertical-align: top;
        color: #fff;
        height:60px;
         span,
         a {
            display: block;
            height: 100%;
            text-decoration: none;
            color: inherit;
            text-align: center;
        }

        .ico-holder {
            margin-bottom: 5px;
            height: 35px;
            text-align: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.edit-customer-wrap {
    padding-bottom: 75px;
}
