.login-holder {
    padding: 15px;

    .logo-holder {
        text-align: center;
    }

    .logo {
        display: inline-block;
        vertical-align: top;
        width: 180px;

        a {
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .login-form-wrap {
        padding: 20px 0;
        max-width: 500px;
        margin: 0 auto;
        min-height: calc(~"100vh - 115px");
        display: flex;
        display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */

        .login-form-inner {
            min-height: calc(~"100vh - 155px");
            display: flex;
            display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
            display: -ms-flexbox;  /* TWEENER - IE 10 */
            display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
            width: 100%;
            align-items: center;
            justify-content: center;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
        }

    }

    .form-header,
    .form-footer {
        color: #000;
        background: #ffff99;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0 0;
        padding: 20px;

        h3 {
            font-size: 20px;
            margin: 0;
        }
    }

    .form-body {
        background: #d9ffb2;
        padding: 35px 20px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }

    .form-footer {
        border-radius: 0 0 10px 10px;

        .btn {
            font-weight: normal;
        }

        .btn-link {
            color: #0D83DD;
            text-decoration: none;
        }
    }
}

.login-form {

    .error-block{
        margin-right:110px;
        padding: 7px 10px 7px 0;
    }

    position: relative;
    width: 100%;

    &:after {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        right: 15px;
        background: url('../../../assets/img/shadow_right.png');
    }

    &:before {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        left: 15px;
        background: url('../../../assets/img/shadow_left.png');
    }

    .form-group {
        margin: 0 0 15px;
    }

    .form-group-row {
        display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .input-holder {
        display: block;
        width:100%;
    }

    .label-holder {
        display: block;
        width:100%;
    }

    .form-control {
        width: 100%;
        display: block;
        padding: 4px 8px;
        border-radius: 0;
        background: #f6f6f6;
        border: 1px solid #cfcfcf;
        outline: none;
        color: #000;
        box-shadow: none;
        font: 17px/22px Arial, Helvetica, sans-serif;
        height: 32px;
        -webkit-appearance: none;
    }

    .checkbox-wrap {

        .custom-label {
            float: right;
            padding-left: 10px;
        }

        .custom-checkbox {
            float: right;
        }

        input[type="checkbox"] {
            position: absolute;
            width: 1px;
            height: 1px;
            visibility: hidden;
            opacity: 0;
        }
    }
}

.preloader-holder {
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;

    .logo-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        animation-name: fade-out-animate;
        animation-duration: .6s;
        animation-delay: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        -webkit-animation-name: fade-out-animate;
        -webkit-animation-duration: .6s;
        -webkit-animation-delay: 1s;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-fill-mode: both;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.email-description {
    text-align: center;
    padding: 15px;
}

@keyframes fade-out-animate {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes fade-out-animate {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}