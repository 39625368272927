.customer-details-holder {
    padding: 0 15px;

    h1 {
        text-align: center;
        margin: 0 0 10px;


    }
}

.customer-details-holder {


    .message-holder{
        padding: 0 5px;
        font-size: 13px;
    }

    .form-group {
        overflow: hidden;
        margin: 0 -5px 5px;

        .input-holder {
            width: 50%;
            float: left;
            padding: 0 5px;

            &:only-child {
                width: 100%;
            }
        }

        .form-control {
            width: 100%;
            display: block;
            padding: 4px 8px;
            border-radius: 6px;
            background: #f6f6f6;
            border: 1px solid #cfcfcf;
            outline: none;
            box-shadow: none;
            font: 17px/22px Arial, Helvetica, sans-serif;
            height: 32px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: #b2b2b2;

            .placeholder({
                color: #b2b2b2;
            })
        }

        textarea {
            font: inherit;
            resize: vertical;
            min-height: 100px;
        }

        .select-holder {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
                border-top: 7px solid #cfcfcf;
                top: 50%;
                transform: translateY(-50%);
                left: 8px;
                pointer-events: none;

            }

            .form-control {
                padding: 4px 8px 4px 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.note-holder {
    font-size: 14px;
    padding: 5px 25px;
    margin-bottom: 10px;
}

.submit-holder {
    text-align: center;

    .btn {
        width: 145px;
    }
}

