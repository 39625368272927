

/*Placeholder mixin*/
.placeholder(@rules) {

    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
}


.input-with-icon-container {
    position: relative;

    input.input-with-icon,
    input.form-control.input-with-icon{
        display: inline;
        padding-right: 33px;
    }

    .input-with-icon+.input-icon{
        display: inline;
        position: absolute;
        top:5px;
        right:5px;
    }

    textarea {
        min-height: 65px;
        display: inline-block;
        vertical-align: top;
        line-height: 1.2;
    }
}

.small {
    .input-with-icon-container {
        textarea {
            min-height: 24px;
        }
    }
}


.btn {

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.45;
        box-shadow: none;
    }
}

.has-error,
.form-group.has-error {
    input.form-control {
        border-width: 1px;
        border-color: #ed1c24!important;
    }
}

.has-error {
    color: #ed1c24;

}

.form-group {

    input.form-control.has-error {
        border-color: #ed1c24!important;
    }
}

html.mobile-version {
    height: 100%;

    body {
        height: 100%;
        margin: 0;

        &.modal-open {
            position: fixed;
            height: 100vh;
            overflow: hidden;
            width: 100%;
            left: 0;
        }

    }

    * {
        box-sizing: border-box;
    }
}

.form-control[type=date] {

}


.form-control {
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}

.btn {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    padding: 7px;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    border-radius: 4px;
    outline: none;
    text-decoration: none;

    &.btn-small {
        padding: 4px 8px;
        font-size: 14px;
    }

    &.btn-success {
        color: #fff;
        background: #85c51f;
        border: 1px solid #6da619;
    }

    &.btn-primary {
        background: #6eb5eb;
        color: #fff;
        border: 1px solid #0d83dd;
    }

    &.btn-warning {
        background: #FDD54D;
        color: #1783D8;
        border: 1px solid #1783D8;
    }
  
    &.btn-gray {
        background: #888;
        border: 1px solid #666;
        color: #fff;
        &.active{
            background: #555555;
        }
    }

    &.btn-link {
        background: transparent;
        border: none;
        color:  #0d83dd;
        text-decoration: underline;
    }
}

.btn-info {
    width: 22px;
    height: 22px;
    display: block;
    border-radius: 50%;
    color: #fff;
    padding: 0;
    line-height: 26px;
    font-size: 14px;
    border: none;
    outline: none;
    background: #0d83dd;
    text-align: center;
    text-decoration: none;
}


.add-btn {
    text-decoration: none;
    color: #fff;
    top: 8px;
    left: 8px;
    width: 13px;
    height: 13px;
    display: block;
    background-image: url("../../assets/img/mobile/ico_plus_saturated.png");
}

.close-btn {
    text-decoration: none;
    color: #fff;
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
    display: block;
    background-image: url("../../assets/img/mobile/ico_minus.png");
}

.page-holder {
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
}

.wrap {
    transition: margin .3s linear;
}

.active-menu {

    .wrap {
        margin: 0 220px 0 -220px;
    }
}

.content {
    padding: 15px 0 0;
}

.has-shadow {
    position: relative;

    &:after {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        right: 5px;
        background: url('../../../assets/img/shadow_right.png');
    }

    &:before {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        left: 5px;
        background: url('../../../assets/img/shadow_left.png');
    }
}

.custom-checkbox {
    display: block;
    cursor: pointer;
    height: 16px;
    width: 16px;
    position: relative;
    border: 1px solid #5e5e5d;
    background: #fff;
    line-height: 1.428571;

    &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 10px;
        top: 0;
        left: 50%;
        border-right: 3px solid #000;
        border-bottom: 3px solid #000;
        transform: translateX(-50%) rotate(45deg);
        -webkit-transform: translateX(-50%) rotate(45deg);
        -ms-transform: translateX(-50%) rotate(45deg);
        opacity: 0;
        transition: opacity .2s linear;
    }
}

.custom-radio {
    display: block;
    cursor: pointer;
    height: 16px;
    width: 16px;
    position: relative;
    border: 1px solid #cdcdcd;
    background: #dadada;
    border-radius: 50%;
    line-height: 1.428571;

    &:before {
        content: '';
        position: absolute;
        height: 9px;
        border-radius: 50%;
        width: 9px;
        top: 50%;
        left: 50%;
        background: #706f6f;
        transform: translate(-50%,-50%);
        opacity: 0;
        transition: opacity .2s linear;
    }
}

.radio-wrap {

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

}

.select-holder {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-top: 7px solid #cfcfcf;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        pointer-events: none;

    }

    .form-control {
        padding: 4px 8px 4px 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

select::-ms-expand {
    display: none;
}

.form-control {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    font-family: inherit;
    box-shadow: none;
}

input[type="checkbox"]:checked + .custom-checkbox:before {
    opacity: 1;
}

input[type="checkbox"]:checked + .custom-checkbox + .custom-label {
    color: #b2b2b2;
}

.radio-wrap input[type="radio"]:checked + .custom-radio:before{opacity:1;}

form input[type="checkbox"]:checked + .custom-checkbox + .custom-label{color: #b2b2b2;}


.SumoSelect:focus{
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.SumoSelect.open > .optWrapper {
    top: 0px;
    display: block;
    right: 100%;
    width: 75%;
}

.SumoSelect.open .search-txt{
    height: 100%;
    padding: 0px 8px;
}

.SumoSelect {
    display:block;
}

.SumoSelect > .CaptionCont {
    width:100%;
    display:block;
    border:2px inset #e5e5e5;
    border-radius:0;
    background-color:#FFFFFF;
    width:100%;
    padding:0 7px 0 40px;
    height:33px;
    font: 18px/1.4 'fahrenheit', Arial, Helvetica, sans-serif;
    color:#575757;
    cursor:pointer;
}

.SumoSelect > .CaptionCont > span {
    padding:0;
    line-height:29px;
    cursor:pointer;
}

.SumoSelect > .CaptionCont > span.placeholder {
    font-style:normal;
}

.SumoSelect:focus > .CaptionCont,
.SumoSelect:hover > .CaptionCont {box-shadow: none;}


.SumoSelect > .CaptionCont > label {
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:auto;
    height:100%;
    width:36px;
    cursor:pointer;
}

.SumoSelect > .CaptionCont > label > i {
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top: 9px solid #dadada;
    margin: -4px auto 0 auto;
    top: 50%;
    background:none;
}

.SumoSelect > .optWrapper.open {
    top:0;
    left:auto;
    right:100%;
    border-radius:0;
    min-width:148px;
    width:auto;
    transition:none;
    -moz-transition:none;
    -webkit-transition:none;

    border:1px solid #ddd;
    box-shadow:none;
}

.SumoSelect > .optWrapper.multiple > .options > li,
.SumoSelect > .optWrapper.multiple > .options > li:first-child,
.SumoSelect > .optWrapper.multiple > .options > li:last-child {
    padding:0 5px 0 5px;
    border:none;
    border-radius:0;
}

.SumoSelect > .optWrapper.multiple > .options > li span,
.SumoSelect .select-all > span {
    display:none;
}

.SumoSelect > .optWrapper > .options {
    border-radius:0;
}

.SumoSelect > .optWrapper > .options > li:hover,
.SumoSelect > .optWrapper > .options > li.selected.sel
{
    color:#ffffff;
    background:#4db2ff;
}

.SumoSelect > .optWrapper > .select-all.selected,
.SumoSelect > .optWrapper > .options > li.selected {
    color:#ffffff;
    background:#0d83dd;
}

.paginator-block {
    display: block;
    padding-bottom: 20px;

    > div {
        overflow: hidden;
        padding: 15px 5px;
    }

    .pager-input {
        padding: 0;
        text-align: right;
        float: none;
        width: auto;

        .form-control {
            width: 40px;
            display: block;
            border: 1px solid #ddd;
            margin: 5px 0 0 auto;
            box-shadow: none;
            border-radius: 5px;
            padding: 5px;
        }
    }

    .pager-container {
        text-align: center;
        padding: 23px 0 0;
        float: none;
        width: auto;
    }

    .pagination  {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height:0;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        border: 1px solid #ddd;
        text-align: center;

        li {
            line-height: 1;
            font-size: 14px;
            display: inline-block;
            vertical-align: top;
            color: #0D83DD;

            &:first-child,
            &:last-child {
                color: #777;
            }

            &:first-child {
                border-left: 1px solid #ddd;
            }

            &:last-child {
                border-right: 1px solid #ddd;
            }

            &.active {
                background: #0D83DD;
                color: #fff;
            }



            a {
                text-decoration: none;
                color: inherit;
                display: block;
                padding: 6px 12px;

            }
        }
    }
}

.select-holder.search-holder {



    .form-control.ui-select-search {
        border: 1px solid #dadada;

    }
    input[type="search"].form-control.ui-select-search::-webkit-search-decoration,
    input[type="search"].form-control.ui-select-search::-webkit-search-cancel-button,
    input[type="search"].form-control.ui-select-search::-webkit-search-results-button,
    input[type="search"].form-control.ui-select-search::-webkit-search-results-decoration {
        display: none;
    }

    .ui-select-match{

        .ui-select-toggle {
            font-weight: normal;
        }

        .ui-select-match-text {
            padding-right: 0;
        }

        .ui-select-placeholder{
            font-weight: normal;
        }
        &.btn-default-focus{
            background: none;
            outline: none;
            box-shadow: none;
        }
    }
}

.has-error {


    .ui-select-container{
        border: 1px solid #ed1c24;
    }
}
input[type='date']:after {
    content: attr(placeholder)
}
.ui-select-container {

    .ui-select-choices {
        position: absolute;
        z-index: 99999999;
        background: white;
        padding: 0;
        outline: 1px auto -webkit-focus-ring-color;
        .ui-select-choices-group {
            list-style: none;
        }
    }
}


.circle-btn{
    width:18px;
    height: 18px;
    color: #fff;
    border:none;
    border-radius:50%;
    font-size:12px;
    padding: 3px;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
}
.circle-btn.add-circle{
    background: #659C17;
}

.circle-btn.remove-circle{
    background: #ed1c24;
}

.money-amount{
    direction: ltr;
    unicode-bidi: bidi-override;
    >span,
    >.ng-binding,
    >.ng-scope{
        direction: ltr;
        unicode-bidi: bidi-override;
    }
}

@import "mobile/home/home.less";
@import "mobile/layouts/header/header.less";
@import "mobile/components/help/help.less";
@import "mobile/components/mobile-common/mobile-common.less";
@import "mobile/components/my-documents/my-documents.less";
@import "mobile/components/my-customers/my-customers.less";
@import "mobile/components/my-documents-customers/my-documents-customers.less";
@import "mobile/components/login/login.less";
@import "mobile/components/new-document/new-document.less";
@import "mobile/components/new-customer/new-customer.less";
@import "mobile/components/view-customer/view-customer.less";
@import "mobile/components/view-document/view-document.less";
@import "mobile/components/contact-us/contact-us.less";



