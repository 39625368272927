/*Placeholder mixin*/
.input-with-icon-container {
  position: relative;
}
.input-with-icon-container input.input-with-icon,
.input-with-icon-container input.form-control.input-with-icon {
  display: inline;
  padding-right: 33px;
}
.input-with-icon-container .input-with-icon + .input-icon {
  display: inline;
  position: absolute;
  top: 5px;
  right: 5px;
}
.input-with-icon-container textarea {
  min-height: 65px;
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
}
.small .input-with-icon-container textarea {
  min-height: 24px;
}
.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  opacity: 0.45;
  box-shadow: none;
}
.has-error input.form-control,
.form-group.has-error input.form-control {
  border-width: 1px;
  border-color: #ed1c24!important;
}
.has-error {
  color: #ed1c24;
}
.form-group input.form-control.has-error {
  border-color: #ed1c24!important;
}
html.mobile-version {
  height: 100%;
}
html.mobile-version body {
  height: 100%;
  margin: 0;
}
html.mobile-version body.modal-open {
  position: fixed;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  left: 0;
}
html.mobile-version * {
  box-sizing: border-box;
}
.form-control {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}
.btn {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding: 7px;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
}
.btn.btn-small {
  padding: 4px 8px;
  font-size: 14px;
}
.btn.btn-success {
  color: #fff;
  background: #85c51f;
  border: 1px solid #6da619;
}
.btn.btn-primary {
  background: #6eb5eb;
  color: #fff;
  border: 1px solid #0d83dd;
}
.btn.btn-warning {
  background: #FDD54D;
  color: #1783D8;
  border: 1px solid #1783D8;
}
.btn.btn-gray {
  background: #888;
  border: 1px solid #666;
  color: #fff;
}
.btn.btn-gray.active {
  background: #555555;
}
.btn.btn-link {
  background: transparent;
  border: none;
  color: #0d83dd;
  text-decoration: underline;
}
.btn-info {
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  line-height: 26px;
  font-size: 14px;
  border: none;
  outline: none;
  background: #0d83dd;
  text-align: center;
  text-decoration: none;
}
.add-btn {
  text-decoration: none;
  color: #fff;
  top: 8px;
  left: 8px;
  width: 13px;
  height: 13px;
  display: block;
  background-image: url("../../assets/img/mobile/ico_plus_saturated.png");
}
.close-btn {
  text-decoration: none;
  color: #fff;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  display: block;
  background-image: url("../../assets/img/mobile/ico_minus.png");
}
.page-holder {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}
.wrap {
  transition: margin .3s linear;
}
.active-menu .wrap {
  margin: 0 220px 0 -220px;
}
.content {
  padding: 15px 0 0;
}
.has-shadow {
  position: relative;
}
.has-shadow:after {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  right: 5px;
  background: url('../../../assets/img/shadow_right.png');
}
.has-shadow:before {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  left: 5px;
  background: url('../../../assets/img/shadow_left.png');
}
.custom-checkbox {
  display: block;
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: relative;
  border: 1px solid #5e5e5d;
  background: #fff;
  line-height: 1.428571;
}
.custom-checkbox:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 10px;
  top: 0;
  left: 50%;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: translateX(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  opacity: 0;
  transition: opacity .2s linear;
}
.custom-radio {
  display: block;
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: relative;
  border: 1px solid #cdcdcd;
  background: #dadada;
  border-radius: 50%;
  line-height: 1.428571;
}
.custom-radio:before {
  content: '';
  position: absolute;
  height: 9px;
  border-radius: 50%;
  width: 9px;
  top: 50%;
  left: 50%;
  background: #706f6f;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity .2s linear;
}
.radio-wrap input[type="radio"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.select-holder {
  position: relative;
}
.select-holder:before {
  content: '';
  position: absolute;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 7px solid #cfcfcf;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  pointer-events: none;
}
.select-holder .form-control {
  padding: 4px 8px 4px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
select::-ms-expand {
  display: none;
}
.form-control {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  font-family: inherit;
  box-shadow: none;
}
input[type="checkbox"]:checked + .custom-checkbox:before {
  opacity: 1;
}
input[type="checkbox"]:checked + .custom-checkbox + .custom-label {
  color: #b2b2b2;
}
.radio-wrap input[type="radio"]:checked + .custom-radio:before {
  opacity: 1;
}
form input[type="checkbox"]:checked + .custom-checkbox + .custom-label {
  color: #b2b2b2;
}
.SumoSelect:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.SumoSelect.open > .optWrapper {
  top: 0px;
  display: block;
  right: 100%;
  width: 75%;
}
.SumoSelect.open .search-txt {
  height: 100%;
  padding: 0px 8px;
}
.SumoSelect {
  display: block;
}
.SumoSelect > .CaptionCont {
  display: block;
  border: 2px inset #e5e5e5;
  border-radius: 0;
  background-color: #FFFFFF;
  width: 100%;
  padding: 0 7px 0 40px;
  height: 33px;
  font: 18px/1.4 'fahrenheit', Arial, Helvetica, sans-serif;
  color: #575757;
  cursor: pointer;
}
.SumoSelect > .CaptionCont > span {
  padding: 0;
  line-height: 29px;
  cursor: pointer;
}
.SumoSelect > .CaptionCont > span.placeholder {
  font-style: normal;
}
.SumoSelect:focus > .CaptionCont,
.SumoSelect:hover > .CaptionCont {
  box-shadow: none;
}
.SumoSelect > .CaptionCont > label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  height: 100%;
  width: 36px;
  cursor: pointer;
}
.SumoSelect > .CaptionCont > label > i {
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top: 9px solid #dadada;
  margin: -4px auto 0 auto;
  top: 50%;
  background: none;
}
.SumoSelect > .optWrapper.open {
  top: 0;
  left: auto;
  right: 100%;
  border-radius: 0;
  min-width: 148px;
  width: auto;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  border: 1px solid #ddd;
  box-shadow: none;
}
.SumoSelect > .optWrapper.multiple > .options > li,
.SumoSelect > .optWrapper.multiple > .options > li:first-child,
.SumoSelect > .optWrapper.multiple > .options > li:last-child {
  padding: 0 5px 0 5px;
  border: none;
  border-radius: 0;
}
.SumoSelect > .optWrapper.multiple > .options > li span,
.SumoSelect .select-all > span {
  display: none;
}
.SumoSelect > .optWrapper > .options {
  border-radius: 0;
}
.SumoSelect > .optWrapper > .options > li:hover,
.SumoSelect > .optWrapper > .options > li.selected.sel {
  color: #ffffff;
  background: #4db2ff;
}
.SumoSelect > .optWrapper > .select-all.selected,
.SumoSelect > .optWrapper > .options > li.selected {
  color: #ffffff;
  background: #0d83dd;
}
.paginator-block {
  display: block;
  padding-bottom: 20px;
}
.paginator-block > div {
  overflow: hidden;
  padding: 15px 5px;
}
.paginator-block .pager-input {
  padding: 0;
  text-align: right;
  float: none;
  width: auto;
}
.paginator-block .pager-input .form-control {
  width: 40px;
  display: block;
  border: 1px solid #ddd;
  margin: 5px 0 0 auto;
  box-shadow: none;
  border-radius: 5px;
  padding: 5px;
}
.paginator-block .pager-container {
  text-align: center;
  padding: 23px 0 0;
  float: none;
  width: auto;
}
.paginator-block .pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid #ddd;
  text-align: center;
}
.paginator-block .pagination li {
  line-height: 1;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  color: #0D83DD;
}
.paginator-block .pagination li:first-child,
.paginator-block .pagination li:last-child {
  color: #777;
}
.paginator-block .pagination li:first-child {
  border-left: 1px solid #ddd;
}
.paginator-block .pagination li:last-child {
  border-right: 1px solid #ddd;
}
.paginator-block .pagination li.active {
  background: #0D83DD;
  color: #fff;
}
.paginator-block .pagination li a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 6px 12px;
}
.select-holder.search-holder .form-control.ui-select-search {
  border: 1px solid #dadada;
}
.select-holder.search-holder input[type="search"].form-control.ui-select-search::-webkit-search-decoration,
.select-holder.search-holder input[type="search"].form-control.ui-select-search::-webkit-search-cancel-button,
.select-holder.search-holder input[type="search"].form-control.ui-select-search::-webkit-search-results-button,
.select-holder.search-holder input[type="search"].form-control.ui-select-search::-webkit-search-results-decoration {
  display: none;
}
.select-holder.search-holder .ui-select-match .ui-select-toggle {
  font-weight: normal;
}
.select-holder.search-holder .ui-select-match .ui-select-match-text {
  padding-right: 0;
}
.select-holder.search-holder .ui-select-match .ui-select-placeholder {
  font-weight: normal;
}
.select-holder.search-holder .ui-select-match.btn-default-focus {
  background: none;
  outline: none;
  box-shadow: none;
}
.has-error .ui-select-container {
  border: 1px solid #ed1c24;
}
input[type='date']:after {
  content: attr(placeholder);
}
.ui-select-container .ui-select-choices {
  position: absolute;
  z-index: 99999999;
  background: white;
  padding: 0;
  outline: 1px auto -webkit-focus-ring-color;
}
.ui-select-container .ui-select-choices .ui-select-choices-group {
  list-style: none;
}
.circle-btn {
  width: 18px;
  height: 18px;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 12px;
  padding: 3px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
}
.circle-btn.add-circle {
  background: #659C17;
}
.circle-btn.remove-circle {
  background: #ed1c24;
}
.money-amount {
  direction: ltr;
  unicode-bidi: bidi-override;
}
.money-amount > span,
.money-amount > .ng-binding,
.money-amount > .ng-scope {
  direction: ltr;
  unicode-bidi: bidi-override;
}
.home-screen-block {
  position: relative;
  min-height: 100vh;
}
.home-screen-block .logo-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-screen-block .logo-wrap img {
  max-width: 100%;
  height: auto;
}
.header-hide {
  display: none;
}
.header-holder {
  padding: 10px;
  position: relative;
}
.header-holder .header-search {
  overflow: hidden;
  font-size: 17px;
  line-height: 20px;
}
.header-holder .header-search .form-group {
  position: relative;
  overflow: hidden;
}
.header-holder .header-search .form-control {
  width: 100%;
  display: block;
  height: 30px;
  padding: 5px 15px 5px 40px;
  background: #E6F4FC;
  border-radius: 6px;
  box-shadow: none;
  border: none;
  outline: none;
  color: #6FB5EA;
}
.header-holder .header-search .form-control::-webkit-input-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.header-holder .header-search .form-control:-moz-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.header-holder .header-search .form-control::-moz-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.header-holder .header-search .form-control:-ms-input-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.header-holder .header-search .search-button {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: none;
  width: 30px;
  outline: none;
  border: none;
  border-radius: 6px;
  color: #6FB5EA;
}
.header-holder .header-search .search-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.header-holder .main-nav {
  float: right;
  width: 40px;
}
.header-holder .menu-opener {
  position: relative;
  width: 32px;
  height: 32px;
  outline: none;
  background: none;
  border: none;
}
.header-holder .menu-opener:after,
.header-holder .menu-opener:before,
.header-holder .menu-opener span {
  top: 12px;
  position: absolute;
  height: 4px;
  width: 24px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  background: #1985d8;
}
.header-holder .menu-opener:before {
  content: '';
  top: 4px;
}
.header-holder .menu-opener:after {
  content: '';
  top: 20px;
}
.nav-drop {
  width: 220px;
  background: #1985d8;
  font-size: 17px;
  padding-bottom: 50px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 100%;
  color: #fff;
}
.nav-drop .nav-title {
  display: block;
  background: #166bad;
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid #fff;
}
.nav-drop ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-drop ul li {
  border-bottom: 1px solid #fff;
}
.nav-drop ul li.active {
  background: #166bad;
}
.nav-drop ul a {
  padding: 8px 15px;
  color: inherit;
  display: block;
  text-decoration: none;
}
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
  z-index: 9999;
}
.search-popup .modal-header {
  display: none;
}
.search-popup .close-btn {
  left: 10px;
  position: absolute;
  background-image: url(../../../../assets/img/mobile/ico_close.png);
}
.search-popup .modal-dialog {
  overflow: auto!important;
}
.search-box {
  overflow: hidden;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 30px;
}
.search-box .form-group {
  position: relative;
  overflow: hidden;
}
.search-box .form-control {
  width: 100%;
  display: block;
  height: 30px;
  padding: 5px 40px 5px 10px;
  background: #E6F4FC;
  border-radius: 6px;
  box-shadow: none;
  border: none;
  outline: none;
  color: #6FB5EA;
}
.search-box .form-control::-webkit-input-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.search-box .form-control:-moz-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.search-box .form-control::-moz-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.search-box .form-control:-ms-input-placeholder {
  color: #6FB5EA;
  text-align: center;
  text-transform: uppercase;
}
.search-box .search-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  width: 30px;
  outline: none;
  border: none;
  border-radius: 6px;
  color: #6FB5EA;
}
.search-box .search-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search-result-row ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.search-result-row li {
  padding: 7px 0 7px 20px;
  position: relative;
  color: #c6c6c6;
}
.search-result-row li:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  border-right: 7px solid #0d83dd;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.search-result-row li b {
  font-weight: bold;
  color: #000;
}
.search-autocomplete {
  padding: 35px 10px 30px;
}
.search-result-title {
  background: #f6f6f6;
  padding: 5px 30px 5px 5px;
  position: relative;
  font-weight: bold;
  color: #000;
}
.search-result-title .ico-holder {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 21px;
  line-height: 1;
}
.search-result-title .ico-holder img {
  display: block;
}
.help-title {
  background: #1985d8;
  padding: 15px 15px;
  color: #fff;
  margin-bottom: 12px;
  text-align: center;
}
.help-title h1 {
  font-size: 35px;
  margin: 0;
  padding: 0 5px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.help-title h1 img {
  position: absolute;
  bottom: 20px;
  left: 100%;
}
/* help accordion styles*/
.help-section {
  color: #000;
  font-size: 16px;
  line-height: 1.2;
}
.help-section h4 {
  margin: 0;
  font-size: 18px;
}
.help-section h4 a {
  color: inherit;
  text-decoration: none;
}
.help-section .panel {
  margin-bottom: 3px;
}
.help-section .panel.collapse-active .collapse {
  display: block;
}
.help-section .panel.collapse-active .panel-heading a:before {
  transform: translateY(-50%) rotate(-90deg);
}
.help-section .panel-heading {
  padding: 0;
}
.help-section .panel-heading a {
  display: block;
  padding: 10px 10px 10px 35px;
  background: #F6F6F6;
  position: relative;
}
.help-section .panel-heading a:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid #1985d8;
}
.help-section .panel-body {
  padding: 15px 10px;
}
.help-section p {
  margin: 0;
}
.help-section .collapse {
  display: none;
}
.help-section .play-video {
  background-color: #F6F6F6;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #CCC;
  border-radius: 2px;
  background: #F6F6F6;
  overflow: hidden;
  position: relative;
}
.help-section .play-video > img {
  width: 100%;
  height: 100%;
}
.help-section .play-video:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 100;
  width: 80px;
  height: 80px;
  background: #DADADA url(../../img/ico_play.png);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}
.bootbox.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  height: 100%;
  width: 100%;
}
.bootbox.modal .modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 1100;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}
.bootbox.modal .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #fff;
}
.modal.yellow-modal {
  transition: opacity .2s linear, visibility .2s linear;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.yellow-modal .modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 999;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  background: #d9ffb2;
}
.modal.yellow-modal:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.modal.yellow-modal.active-popup {
  opacity: 1;
  visibility: visible;
}
.modal.yellow-modal .popup-title {
  padding: 10px;
  background: #ffff99;
}
.modal.yellow-modal .popup-title h3 {
  font-size: 18px;
  margin: 0;
}
.modal.yellow-modal .modal-body {
  padding: 10px;
}
.modal.yellow-modal .has-error .form-control {
  border: 1px solid #ed1c24;
}
.modal.yellow-modal .form-group {
  margin-bottom: 10px;
}
.modal.yellow-modal .form-control {
  width: 100%;
  border: none;
  padding: 8px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
}
.modal.yellow-modal .btn-holder {
  text-align: left;
}
.modal.yellow-modal .close-btn {
  position: absolute;
  left: 7px;
  top: 7px;
  background-image: url("../../../assets/img/mobile/ico_close.png");
}
.overlay-popup.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: none;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.overlay-popup.modal .modal-backdrop {
  z-index: 999;
}
.overlay-popup.modal .overlay-modal-header {
  position: relative;
  padding: 20px 40px;
  background: #f6f6f6;
}
.overlay-popup.modal .overlay-modal-header .btn-info {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.overlay-popup.modal .overlay-modal-header h2 {
  font-size: 22px;
  margin: 0;
}
.overlay-popup.modal .overlay-modal-header .close-btn {
  position: absolute;
  top: 7px;
  left: 7px;
}
.overlay-popup.modal .overlay-modal-header .close-btn:after,
.overlay-popup.modal .overlay-modal-header .close-btn:before {
  background: #b2b2b2;
}
.overlay-popup.modal .modal-dialog {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  position: absolute;
}
.overlay-popup.modal .edit-form {
  background: #fff;
  padding-bottom: 20px;
}
/* Modal styles*/
.common-popup.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  outline: none;
}
.common-popup.modal .modal-header {
  height: 0;
}
.common-popup.modal .modal-header * {
  margin: 0;
}
.common-popup.modal .modal-dialog {
  z-index: 1050;
}
.common-popup.modal .close {
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 18px;
  height: 18px;
  display: block;
  outline: none;
}
.common-popup.modal .close:after,
.common-popup.modal .close:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 2px;
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
  -moz-transform: translate(50%, -50%) rotate(-45deg);
  -ms-transform: translate(50%, -50%) rotate(-45deg);
  -o-transform: translate(50%, -50%) rotate(-45deg);
  background: #fff;
}
.common-popup.modal .close:after {
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(50%, -50%) rotate(45deg);
  -moz-transform: translate(50%, -50%) rotate(45deg);
  -ms-transform: translate(50%, -50%) rotate(45deg);
  -o-transform: translate(50%, -50%) rotate(45deg);
}
.common-popup.modal .close .fa {
  display: none;
}
.common-popup.modal .modal-inner {
  background: #0d83dd;
  border-radius: 10px;
  padding: 35px 25px 10px;
  text-align: center;
  position: relative;
  z-index: 1000;
  max-height: 92vh;
  overflow-y: auto;
  width: 290px;
}
.common-popup.modal .modal-content {
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.common-popup.modal .modal-content p {
  margin: 0 0 20px;
}
.common-popup.modal .modal-image {
  margin-bottom: 20px;
}
.common-popup.modal .add-info {
  padding: 5px 0;
  display: block;
  font-size: 13px;
}
/*popup document styles*/
.popup-document .modal-dialog {
  border-radius: 10px;
  border: 7px solid #DCECFB;
  overflow: hidden;
  width: 90%;
  z-index: 9999;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.5);
}
.popup-document .modal-dialog:after {
  content: '';
  width: 60px;
  height: 60px;
  background: #DCECFB;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(45deg);
  -moz-transform: translate(50%, -50%) rotate(45deg);
  -ms-transform: translate(50%, -50%) rotate(45deg);
  -o-transform: translate(50%, -50%) rotate(45deg);
  transform: translate(50%, -50%) rotate(45deg);
}
.popup-document .modal-header {
  font-size: 24px;
  color: #000;
  padding: 30px 40px;
  border-bottom: 7px solid #DCECFB;
  position: relative;
}
.popup-document .modal-header .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  color: #6FB5E8;
  z-index: 2;
}
.popup-document h4 {
  margin: 0;
}
.popup-document .modal-body {
  padding: 25px 20px;
  max-height: 70vh;
  overflow: auto;
}
.popup-document .modal-footer {
  border-top: 7px solid #DCECFB;
  padding: 15px 40px;
  text-align: center;
}
.modal-backdrop {
  position: fixed;
  top: -9999px;
  left: -9999px;
  bottom: -9999px;
  right: -9999px;
  background: #fff;
  opacity: 0;
  z-index: 800;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}
.modal-backdrop.in {
  opacity: 0.8;
}
.my-documents-filter {
  position: relative;
  padding: 15px 80px;
  background: #f6f6f6;
  text-align: center;
  min-height: 45px;
}
.my-documents-filter .btn-holder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 70px;
  padding: 0 5px;
}
.my-documents-filter .btn-holder .btn {
  color: #fff;
  border-radius: 6px;
  width: 100%;
  background: #6eb5eb;
  border: 1px solid #3c8fce;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  outline: none;
  padding: 5px;
}
.my-documents-filter .result-box {
  overflow: hidden;
  padding-left: 10px;
}
.my-documents-filter .result-box span {
  font-weight: bold;
}
.my-documents-filter .result-box p {
  margin: 0;
}
.my-documents-title {
  text-align: center;
  margin-bottom: 10px;
}
.my-documents-title h1 {
  font-size: 24px;
  margin: 0;
}
.my-documents-items {
  background: #0d83dd;
  padding: 10px;
  overflow: hidden;
}
.document-item {
  position: relative;
  margin-bottom: 15px;
  transition: margin .2s linear;
  -webkit-transition: margin .2s linear;
  -moz-transition: margin .2s linear;
  -o-transition: margin .2s linear;
}
.document-item.option-active {
  margin-left: 190px;
  margin-right: -190px;
}
.document-item.inactive-item .img-holder .document-img-inactive {
  display: block;
}
.document-item.inactive-item .document-label {
  background: #9f1e00;
}
.document-item.active-item .img-holder .document-img-active {
  display: block;
}
.document-info.has-shadow:after {
  width: 110px;
  height: 7px;
  background: url('../../../assets/img/mobile/img-shadow-right-document.png') no-repeat;
}
.document-info.has-shadow:before {
  width: 110px;
  height: 7px;
  background: url('../../../assets/img/mobile/img-shadow-left-document.png') no-repeat;
}
.document-info-inner {
  background: #fff;
  position: relative;
  z-index: 3;
  padding: 10px 60px 10px 47px;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
}
.document-info-inner:before {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #0d83dd;
  border-right: 2px solid #0d83dd;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.document-info-inner .price-container {
  text-align: center;
  height: 30px;
}
.document-info-inner .price {
  padding: 5px;
  font-size: 18px;
  color: #6eb5eb;
  font-weight: bold;
  border: 1px solid #6eb5eb;
  max-width: 160px;
  height: 100%;
  display: inline-block;
}
.document-info-inner .details-container {
  width: 100%;
  white-space: nowrap;
}
.document-info-inner .details-container > span {
  display: inline-block;
  margin-left: 10px;
}
.document-info-inner p {
  margin: 0 0 2px;
}
.document-info-inner .date {
  display: block;
}
.document-info-inner h4 {
  font-size: 16px;
  margin: 0 0 2px;
}
.document-info-inner .img-holder {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.document-info-inner .img-holder img {
  display: none;
}
.document-options {
  overflow: hidden;
  width: 200px;
  position: absolute;
  top: 0;
  padding: 0 5px;
  right: 100%;
}
.document-options .btn {
  width: 85px;
  border-radius: 8px;
  border: none;
  background: #0a69b1;
  color: #fff;
  text-align: center;
  float: left;
  margin: 0 5px;
  padding: 22px 10px;
  font-size: 14px;
  font-weight: 500;
}
.document-options .btn span {
  display: block;
  padding: 2px;
}
.document-label {
  position: absolute;
  width: 80px;
  background: #85c51f;
  color: #fff;
  text-align: center;
  font-weight: bold;
  left: -22px;
  top: 8px;
  font-size: 14px;
  line-height: 1;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  padding: 5px;
}
.my-documents-filter-popup {
  background: #0d83dd;
  position: fixed;
  top: 0;
  left: -270px;
  bottom: 0;
  width: 270px;
  z-index: 9999;
  transition: left .2s linear;
  -webkit-transition: left .2s linear;
  -moz-transition: left .2s linear;
  -o-transition: left .2s linear;
}
.my-documents-filter-popup.filter-popup-active {
  left: 0;
}
.my-documents-filter-popup.filter-popup-active .popup-overlay {
  opacity: .8;
  visibility: visible;
}
.my-documents-filter-popup .popup-overlay {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 999px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s linear;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
}
.my-documents-filter-popup .filter-popup-wrap {
  overflow-y: auto;
  max-height: 100vh;
  padding-bottom: 50px;
}
.my-documents-filter-popup .filter-row {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
}
.my-documents-filter-popup .filter-row.filter-row-active .filter-panel {
  font-weight: bold;
  background: #f6f6f6;
}
.my-documents-filter-popup .filter-row.filter-row-active .filter-panel .filter-icon {
  background: #f90d0d;
}
.my-documents-filter-popup .filter-row.filter-row-active .filter-panel .filter-icon:after {
  height: 0;
}
.my-documents-filter-popup .filter-row.has-shadow:after {
  width: 110px;
  height: 7px;
  background: url('../../../assets/img/mobile/img-shadow-right-document.png') no-repeat;
}
.my-documents-filter-popup .filter-row.has-shadow:before {
  width: 110px;
  height: 7px;
  background: url('../../../assets/img/mobile/img-shadow-left-document.png') no-repeat;
}
.my-documents-filter-popup .filter-slide {
  display: none;
}
.my-documents-filter-popup .filter-header {
  background: #0a69b1;
  padding: 10px 65px;
  color: #fff;
  position: relative;
  text-align: center;
}
.my-documents-filter-popup .filter-header h5 {
  text-align: center;
  margin: 0;
  font-size: 20px;
}
.my-documents-filter-popup .filter-header a {
  color: inherit;
}
.my-documents-filter-popup .filter-header .btn-finish {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  border: none;
  background: none;
  color: #fff;
  text-align: center;
  outline: none;
}
.my-documents-filter-popup .filter-header .btn-finish:after {
  margin: 0 2px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.my-documents-filter-popup .filter-header .btn-clear {
  outline: none;
  text-decoration: none;
  position: absolute;
  top: 50%;
  background: none;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 10px;
  color: #fff;
  border: none;
}
.my-documents-filter-popup .filter-body {
  padding: 20px 20px;
}
.my-documents-filter-popup .filter-panel {
  padding: 13px 10px 13px 40px;
  border-radius: 8px 8px 0 0;
  position: relative;
  transition: background .2s linear;
  -webkit-transition: background .2s linear;
  -moz-transition: background .2s linear;
  -o-transition: background .2s linear;
}
.my-documents-filter-popup .filter-panel .filter-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  border-radius: 50%;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  background: #85c51f;
}
.my-documents-filter-popup .filter-panel .filter-icon:after,
.my-documents-filter-popup .filter-panel .filter-icon:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: height .2s linear;
  -webkit-transition: height .2s linear;
  -moz-transition: height .2s linear;
  -o-transition: height .2s linear;
}
.my-documents-filter-popup .filter-panel .filter-icon:after {
  width: 2px;
  height: 10px;
}
.my-documents-filter-popup .filter-panel h6 {
  display: inline-block;
  font-size: 18px;
  margin: 0;
}
.my-documents-filter-popup .filter-inner {
  font-size: 0;
  line-height: 0;
  padding: 20px 15px 10px;
}
.my-documents-filter-popup .filter-inner.filter-inner-checkbox {
  margin: 0 -10px;
}
.my-documents-filter-popup .filter-inner .filter-col {
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.2;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.my-documents-filter-popup .checkbox-wrap .custom-label {
  overflow: hidden;
  padding-right: 3px;
  width: calc(100% - 16px);
  display: block;
}
.my-documents-filter-popup .checkbox-wrap .custom-checkbox {
  float: right;
  border-color: #ccc;
  background: #f6f6f6;
}
.my-documents-filter-popup .checkbox-wrap .custom-checkbox:before {
  border-color: #575756;
}
.my-documents-filter-popup .checkbox-wrap input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  visibility: hidden;
  opacity: 0;
}
.my-documents-filter-popup .amount-wrap {
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}
.my-documents-filter-popup .amount-wrap span {
  display: inline-block;
  vertical-align: middle;
  padding: 0 2px;
}
.my-documents-filter-popup .amount-wrap .form-control {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.my-documents-filter-popup .select-holder {
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.2;
  overflow: hidden;
}
.my-documents-filter-popup .select-holder .form-control {
  width: 100%;
  border: none;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.my-documents-filter-popup .filter-total {
  color: #fff;
  padding: 5px 0;
  border-top: 1px solid #fff;
  font-size: 13px;
  text-align: left;
}
.my-documents-filter-popup .filter-total p {
  margin: 0;
}
.popup-document {
  font: 15px/22px Arial, Helvetica, sans-serif;
  color: #3E3E3E;
}
.popup-document .form-group {
  overflow: hidden;
  margin: 0 0 20px;
}
.popup-document .form-control {
  width: 100%;
  display: block;
  padding: 4px 8px;
  border-radius: 6px;
  background: #f6f6f6;
  border: 1px solid #cfcfcf;
  outline: none;
  box-shadow: none;
  height: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.popup-document .form-control::-webkit-input-placeholder {
  color: #b2b2b2;
}
.popup-document .form-control:-moz-placeholder {
  color: #b2b2b2;
}
.popup-document .form-control::-moz-placeholder {
  color: #b2b2b2;
}
.popup-document .form-control:-ms-input-placeholder {
  color: #b2b2b2;
}
.popup-document label:before {
  content: '*';
  color: #FD001C;
  display: inline-block;
  vertical-align: top;
  padding: 0 4px;
}
.popup-document .label-holder {
  float: right;
  width: 50%;
  padding: 5px 0 5px 10px;
}
.popup-document .input-holder {
  float: right;
  width: 50%;
}
.popup-document textarea {
  min-height: 80px;
  resize: vertical;
}
.popup-document .btn-holder {
  text-align: center;
  padding: 40px 0 0;
}
.modal-footer .btn-holder {
  padding: 0;
}
.customer-holder {
  padding: 0 10px;
}
.customer-panel {
  margin-bottom: 10px;
}
.customer-panel .btn-col {
  width: 115px;
  padding: 0 5px 0 0;
  float: left;
}
.customer-panel .title-col {
  overflow: hidden;
  background: #F6F6F6;
  padding: 10px 10px 20px;
}
.customer-panel .title-col h2 {
  font-size: 20px;
  margin: 0 0 5px;
}
.customer-panel .btn {
  position: relative;
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 16px;
  height: 58px;
}
.customer-panel .btn:after,
.customer-panel .btn:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
  width: 4px;
  height: 14px;
  background: #cee8a5;
}
.customer-panel .btn:after {
  width: 14px;
  height: 4px;
  right: 4px;
}
.customers-wrap .customer-item {
  position: relative;
  padding: 10px 10px 10px 70px;
  border-top: 1px solid #D1D1D1;
  min-height: 85px;
}
.customers-wrap .customer-item:last-child {
  border-bottom: 1px solid #D1D1D1;
}
.customers-wrap .customer-item h4 {
  font-size: 18px;
  margin: 0 0 15px;
}
.customers-wrap .customer-item ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
.customers-wrap .customer-item li {
  margin-bottom: 3px;
}
.customers-wrap .customer-item li a {
  display: inline-block;
  vertical-align: top;
  padding: 4px 22px 4px 4px;
  position: relative;
}
.customers-wrap .customer-item li img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.customers-wrap .btn {
  font-size: 10px;
  width: 60px;
  position: absolute;
  top: 10px;
  left: 0;
}
.customers-wrap .btn:before {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 3px;
  background: url(../../../assets/img/sprites.png) no-repeat;
  background-position: -3px -413px;
}
.customers-wrap .btn span {
  display: block;
}
.documents-holder {
  padding: 10px 10px 70px;
}
.documents-title {
  position: relative;
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
}
.documents-title .documents-title-inner {
  background: #0d83dd;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  padding: 20px;
}
.documents-title h1 {
  font-size: 24px;
  margin: 0;
}
.documents-section {
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.documents-section.sup-active {
  position: relative;
}
.documents-section.sup-active:after {
  content: '';
  position: fixed;
  top: -9999px;
  left: -999px;
  right: -999px;
  z-index: 999;
  bottom: -9999px;
  background: #fff;
  opacity: 0.8;
  animation-name: fade-out-animate;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  -webkit-animation-name: fade-out-animate;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
}
.documents-section.sup-active .sup-img {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 325px;
  animation-name: fade-out-animate;
  animation-duration: 1.1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  -webkit-animation-name: fade-out-animate;
  -webkit-animation-duration: 1.1s;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
}
.documents-section.sup-active .sup-img .documents-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 354px;
  margin-top: 76px;
  height: 67px;
  margin-left: 10px;
}
.documents-section.sup-active .sup-img .documents-title .documents-title-inner {
  height: 100%;
  padding: 33px;
}
.documents-section.sup-active .sup-img img {
  display: block;
  width: 100%;
}
.documents-section .document-item {
  font-size: 16px;
  line-height: 1.2;
  width: 50%;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
}
.documents-section .document-item a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: inherit;
  outline: none;
}
.documents-section .img-holder {
  margin-bottom: 2px;
}
.documents-section .img-holder img {
  max-width: 100%;
  height: auto;
}
.documents-section p {
  margin: 0;
}
.documents-section .sup-img {
  display: none;
}
.documents-number {
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  font-size: 13px;
  line-height: 1;
  border: 1px solid #659c17;
  background: #85c51f;
  padding: 26px 0;
  color: #fff;
  outline: none;
  margin: 3px 0 -3px;
}
.documents-number .img-holder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 12px;
}
.documents-number div {
  position: relative;
  padding: 0 70px;
  display: inline-block;
  vertical-align: top;
}
@keyframes fade-out-animate {
  0% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes fade-out-animate {
  0% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.login-holder {
  padding: 15px;
}
.login-holder .logo-holder {
  text-align: center;
}
.login-holder .logo {
  display: inline-block;
  vertical-align: top;
  width: 180px;
}
.login-holder .logo a {
  display: block;
}
.login-holder .logo img {
  max-width: 100%;
  height: auto;
}
.login-holder .login-form-wrap {
  padding: 20px 0;
  max-width: 500px;
  margin: 0 auto;
  min-height: calc(100vh - 115px);
  display: flex;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
}
.login-holder .login-form-wrap .login-form-inner {
  min-height: calc(100vh - 155px);
  display: flex;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  width: 100%;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
}
.login-holder .form-header,
.login-holder .form-footer {
  color: #000;
  background: #ffff99;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  padding: 20px;
}
.login-holder .form-header h3,
.login-holder .form-footer h3 {
  font-size: 20px;
  margin: 0;
}
.login-holder .form-body {
  background: #d9ffb2;
  padding: 35px 20px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.login-holder .form-footer {
  border-radius: 0 0 10px 10px;
}
.login-holder .form-footer .btn {
  font-weight: normal;
}
.login-holder .form-footer .btn-link {
  color: #0D83DD;
  text-decoration: none;
}
.login-form {
  position: relative;
  width: 100%;
}
.login-form .error-block {
  margin-right: 110px;
  padding: 7px 10px 7px 0;
}
.login-form:after {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  right: 15px;
  background: url('../../../assets/img/shadow_right.png');
}
.login-form:before {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  left: 15px;
  background: url('../../../assets/img/shadow_left.png');
}
.login-form .form-group {
  margin: 0 0 15px;
}
.login-form .form-group-row {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
}
.login-form .input-holder {
  display: block;
  width: 100%;
}
.login-form .label-holder {
  display: block;
  width: 100%;
}
.login-form .form-control {
  width: 100%;
  display: block;
  padding: 4px 8px;
  border-radius: 0;
  background: #f6f6f6;
  border: 1px solid #cfcfcf;
  outline: none;
  color: #000;
  box-shadow: none;
  font: 17px/22px Arial, Helvetica, sans-serif;
  height: 32px;
  -webkit-appearance: none;
}
.login-form .checkbox-wrap .custom-label {
  float: right;
  padding-left: 10px;
}
.login-form .checkbox-wrap .custom-checkbox {
  float: right;
}
.login-form .checkbox-wrap input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  visibility: hidden;
  opacity: 0;
}
.preloader-holder {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
}
.preloader-holder .logo-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  animation-name: fade-out-animate;
  animation-duration: .6s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  -webkit-animation-name: fade-out-animate;
  -webkit-animation-duration: .6s;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
}
.preloader-holder .logo-box img {
  max-width: 100%;
  height: auto;
}
.email-description {
  text-align: center;
  padding: 15px;
}
@keyframes fade-out-animate {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes fade-out-animate {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.new-document-holder .debug-button-container {
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px;
  background: white;
  border: 1px solid silver;
  z-index: 998;
}
.new-document-holder .new-document-title {
  margin-bottom: 10px;
  text-align: center;
}
.new-document-holder .new-document-title h1 {
  font-size: 24px;
  margin: 0;
  text-align: center;
}
.new-document-holder a {
  outline: none;
}
.new-document-holder .discount-block,
.new-document-holder .extras-block {
  border: 1px solid #dadada;
  margin-top: 4px;
  padding-top: 4px;
}
.new-document-holder .discount-block .radio-wrap,
.new-document-holder .extras-block .radio-wrap {
  display: inline-block;
}
.new-document-holder .discount-block .form-control,
.new-document-holder .extras-block .form-control {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
.new-document-holder .discount-block .btn-row,
.new-document-holder .extras-block .btn-row {
  padding: 6px;
  text-align: center;
}
.new-document-holder .item-row,
.new-document-holder .payment-row {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  margin-bottom: 5px;
}
.new-document-holder .item-row .btn-wrap .btn.remove-button,
.new-document-holder .payment-row .btn-wrap .btn.remove-button {
  height: 20px;
  padding: 0;
  width: 95px !important;
}
.new-document-holder .new-document-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.new-document-holder .new-document-nav ul li {
  float: left;
  width: 33.33%;
  height: 40px;
  color: #fff;
  background: #6eb5eb;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  position: relative;
}
.new-document-holder .new-document-nav ul li.active {
  background: #0d83dd;
}
.new-document-holder .new-document-nav ul li.active:after {
  background: #0d83dd;
}
.new-document-holder .new-document-nav ul li:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background: #6eb5eb;
  top: 4px;
  right: 100%;
  transform: translateX(50%) rotate(-45deg);
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.new-document-holder .new-document-nav ul li a {
  display: block;
  padding: 13px 40px 13px 20px;
  text-decoration: none;
  color: inherit;
  position: relative;
  z-index: 3;
  outline: none;
}
.document-type-links {
  padding-top: 20px;
}
.document-type-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.document-type-links ul li {
  color: #000;
  margin-bottom: 7px;
}
.document-type-links ul a {
  display: block;
  color: inherit;
  padding: 18px 40px;
  background: #f6f6f6;
  position: relative;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  line-height: 18px;
}
.document-type-links ul a:after {
  content: '';
  position: absolute;
  border-right: 8px solid #0d83dd;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.document-type-links ul .ico-holder {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
}
.invoice-number-box {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.1;
  position: relative;
  padding: 20px 10px 20px 80px;
}
.document-row-title {
  padding: 15px 10px;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  background: #f6f6f6;
}
.document-row-title.blue-bg-title {
  background: #E6F4FC;
}
.document-row-title h3 {
  font-size: 20px;
  margin: 0;
}
.new-document-form-row {
  font-size: 16px;
}
.new-document-form-row + .new-document-form-row {
  border-top: 1px solid #dadada;
}
.new-document-form-row .wrap_over_input {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.new-document-form-row .wrap_over_input .date-picker {
  width: auto !important;
}
.new-document-form-row .wrap_over_input label {
  padding: 10px;
  text-align: right;
}
.new-document-form-row.two-columns {
  display: flex;
  flex-wrap: wrap;
}
.new-document-form-row.two-columns .form-group {
  width: 50%;
}
.new-document-form-row.two-columns .form-group + .form-group {
  border-right: 1px solid #dadada;
}
.new-document-form-row.btn-holder {
  padding: 10px;
  text-align: center;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
}
.new-document-form-row.btn-holder.customer-btns .btn-wrap .btn {
  height: 35px;
}
.new-document-form-row.btn-holder .btn-wrap {
  width: 50%;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  line-height: 1.1;
}
.new-document-form-row.btn-holder .btn-wrap .btn {
  display: inline-block;
  vertical-align: top;
  height: 55px;
  width: 110px;
}
.new-document-form-row.btn-holder .btn-wrap:only-child {
  width: 100%;
}
.new-document-form-row.btn-holder .btn-wrap:only-child .btn {
  width: 190px;
}
.new-document-form-row.btn-holder .btn-wrap .btn-add-mail {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  width: 190px;
}
.new-document-form-row.btn-holder .btn-wrap .btn-add-mail:after,
.new-document-form-row.btn-holder .btn-wrap .btn-add-mail:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 23px;
  width: 4px;
  height: 14px;
  background: #cee8a5;
}
.new-document-form-row.btn-holder .btn-wrap .btn-add-mail:after {
  width: 14px;
  height: 4px;
  right: 18px;
}
.new-document-form-row .radio-row,
.new-document-form-row .checkbox-row {
  padding: 10px;
}
.new-document-form-row .radio-wrap .custom-label,
.new-document-form-row .checkbox-wrap .custom-label {
  overflow: hidden;
  padding-right: 7px;
  width: calc(100% - 16px);
  display: block;
}
.new-document-form-row .radio-wrap .custom-checkbox,
.new-document-form-row .checkbox-wrap .custom-checkbox,
.new-document-form-row .radio-wrap .custom-radio,
.new-document-form-row .checkbox-wrap .custom-radio {
  float: right;
}
.new-document-form-row .radio-wrap .custom-checkbox,
.new-document-form-row .checkbox-wrap .custom-checkbox {
  background: #F6F6F6;
  border-color: #CBCBCB;
}
.new-document-form-row .radio-wrap input[type="checkbox"],
.new-document-form-row .checkbox-wrap input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
  height: 1px;
  width: 1px;
  opacity: 0;
  left: -9999px;
  top: -9999px;
}
.new-document-form-row .select-holder:before {
  border-top-color: #000;
}
.new-document-form-row input.ng-invalid.ng-touched {
  border-color: #ed1c24!important;
}
.new-document-form-row .form-control {
  color: #000;
  font-size: 16px;
  width: 100%;
  display: block;
  height: 100%;
  min-height: 38px;
  line-height: 20px;
  padding: 9px 10px;
  border-color: transparent;
  background: transparent;
}
.new-document-form-row .form-control[disabled] {
  background: rgba(230, 230, 230, 0.5);
  color: gray;
}
.new-document-form-row .form-control::-webkit-input-placeholder {
  color: #000;
}
.new-document-form-row .form-control:-moz-placeholder {
  color: #000;
}
.new-document-form-row .form-control::-moz-placeholder {
  color: #000;
}
.new-document-form-row .form-control:-ms-input-placeholder {
  color: #000;
}
.new-document-form-row textarea.form-control {
  height: 80px;
  resize: vertical;
}
.new-document-form-row .three-column-summary-table {
  width: 100%;
}
.new-document-form-row .three-column-summary-table td {
  padding: 5px 10px;
  width: 45%;
  text-align: right;
}
.new-document-form-row .three-column-summary-table td:last-child {
  width: 10%;
}
.new-document-form-row .payments-table {
  width: 100%;
}
.new-document-form-row .payments-table td.remove-button-column {
  padding: 0 0 0 5px;
  text-align: left;
}
.new-document-form-row .payments-table td {
  padding: 5px 10px;
  text-align: right;
}
.new-document-form-row .payments-table td:last-child {
  width: 1px;
  white-space: nowrap;
}
.new-document-form-row .summary-table {
  width: 100%;
}
.new-document-form-row .summary-table td {
  padding: 5px 10px;
  text-align: right;
}
.new-document-form-row .summary-table td:last-child {
  width: 1px;
  white-space: nowrap;
}
.new-document-form-row .summary-table tr:last-of-type {
  font-weight: bold;
  font-size: 16px;
}
.new-document-form-row .internal-comment-wrapper {
  padding: 9px 10px;
}
.new-document-form-row .internal-comment-wrapper > * {
  display: inline-block;
  vertical-align: middle;
}
.added-email {
  padding: 10px;
}
.added-email ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.added-email li {
  margin-bottom: 10px;
}
.added-email li .close-btn {
  display: inline-block;
  float: left;
}
.select-payment-type-buttons {
  padding-top: 20px;
}
.select-payment-type-buttons .new-document-form-row.btn-holder {
  border: none;
  padding: 3px;
}
.select-payment-type-buttons .new-document-form-row.btn-holder .btn-wrap .btn.btn-small {
  height: 28px;
}
.btn-docType {
  display: inline-block;
  position: relative;
  height: 140px;
  width: 140px;
  font-size: 22px;
  background-size: 160px;
  background-position-x: -10px;
  background-position-y: -7px;
  border: 1px solid silver;
  border-radius: 12px;
}
.btn-docType.tax_invoice_receipt {
  background-image: url(../../img/tax_invoice_receipt.png);
  background-position-y: -12px;
}
.btn-docType.tax_invoice {
  background-image: url(../../img/tax_invoice.png);
  background-position-y: -11px;
}
.btn-docType.receipt {
  background-image: url(../../img/receipt.png);
  background-position-y: -8px;
}
.btn-docType.order {
  background-image: url(../../img/order.png);
}
.btn-docType.delivery_note {
  background-image: url(../../img/delivery_note.png);
}
.btn-docType.perform {
  background-image: url(../../img/perfoma.png);
  background-position-y: -9px;
}
.btn-docType.price_quote {
  background-image: url(../../img/price_quote.png);
  background-position-y: -11px;
}
.btn-docType.receipt_credit {
  background-image: url(../../img/credit_invoice.png);
  background-position-y: -13px;
}
.btn-docType.payment_request {
  background-image: url(../../img/payment_request.png);
  background-position-y: -11px;
}
.btn-docType.return_form {
  background-image: url(../../img/return_note.png);
  background-position-y: -11px;
}
.btn-docType.balance_adjustment {
  background-image: url(../../img/balance.png);
  background-position-y: -11px;
}
.btn-docType .button-text {
  border-radius: 9px;
  width: 107px;
  height: 36px;
  color: #fff;
  position: absolute;
  display: inline-block;
  font-size: 13px;
  top: 77px;
  left: 15px;
  white-space: nowrap;
}
.btn-docType .button-text > span {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
}
.btn-docType .button-text::after {
  content: '';
  height: 30px;
  width: 1px;
  display: inline-block;
  vertical-align: middle;
}
.row-title-box {
  padding: 9px 10px;
  font-weight: bold;
}
.credit-type-section {
  position: relative;
  background-image: url("../../img/credit_docs_group.png");
  background-size: 310px;
  width: 290px;
  height: 122px;
  border: 1px solid silver;
  border-radius: 12px;
  display: inline-block;
  background-position-x: -10px;
  background-position-y: -7px;
  vertical-align: top;
}
.credit-type-section .title-label {
  position: absolute;
  top: 72px;
  right: 13px;
  height: 27px;
  width: 95px;
  margin-top: 3px;
  color: white;
}
.credit-type-section .button-wrapper {
  position: absolute;
  width: 146px;
  height: 101px;
  top: 8px;
  left: 18px;
}
.credit-type-section .button-wrapper button {
  border: none;
  width: 100%;
  height: 27px;
  background: none;
  color: #135289;
  font-size: 10px;
  margin-bottom: 10px;
}
.customer-details-holder {
  padding: 0 15px;
}
.customer-details-holder h1 {
  text-align: center;
  margin: 0 0 10px;
}
.customer-details-holder .message-holder {
  padding: 0 5px;
  font-size: 13px;
}
.customer-details-holder .form-group {
  overflow: hidden;
  margin: 0 -5px 5px;
}
.customer-details-holder .form-group .input-holder {
  width: 50%;
  float: left;
  padding: 0 5px;
}
.customer-details-holder .form-group .input-holder:only-child {
  width: 100%;
}
.customer-details-holder .form-group .form-control {
  width: 100%;
  display: block;
  padding: 4px 8px;
  border-radius: 6px;
  background: #f6f6f6;
  border: 1px solid #cfcfcf;
  outline: none;
  box-shadow: none;
  font: 17px/22px Arial, Helvetica, sans-serif;
  height: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #b2b2b2;
}
.customer-details-holder .form-group .form-control::-webkit-input-placeholder {
  color: #b2b2b2;
}
.customer-details-holder .form-group .form-control:-moz-placeholder {
  color: #b2b2b2;
}
.customer-details-holder .form-group .form-control::-moz-placeholder {
  color: #b2b2b2;
}
.customer-details-holder .form-group .form-control:-ms-input-placeholder {
  color: #b2b2b2;
}
.customer-details-holder .form-group textarea {
  font: inherit;
  resize: vertical;
  min-height: 100px;
}
.customer-details-holder .form-group .select-holder {
  position: relative;
}
.customer-details-holder .form-group .select-holder:before {
  content: '';
  position: absolute;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 7px solid #cfcfcf;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  pointer-events: none;
}
.customer-details-holder .form-group .select-holder .form-control {
  padding: 4px 8px 4px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.note-holder {
  font-size: 14px;
  padding: 5px 25px;
  margin-bottom: 10px;
}
.submit-holder {
  text-align: center;
}
.submit-holder .btn {
  width: 145px;
}
.customer-details-header .has-error input.name-input {
  border-bottom: 1px solid #ed1c24;
}
.customer-details-header input.name-input {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 22px;
  width: 215px;
}
.customer-details-header .close-btn {
  background-image: url("../../../../assets/img/mobile/ico_close.png");
}
.edit-customer-form .message-holder {
  padding-right: 5px;
}
.edit-customer-form .form-group {
  min-height: 30px;
  border-bottom: 1px solid #ccc;
}
.edit-customer-form .input-holder {
  overflow: hidden;
}
.edit-customer-form .label-holder {
  float: right;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  padding: 8px 5px;
}
.edit-customer-form .form-control {
  width: 100%;
  display: block;
  background: none;
  border: none;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 5px;
}
.edit-customer-form .add-extra-item {
  /*        li {
            padding-left: 30px;
            padding-right: 30px;
        }*/
}
.edit-customer-form ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.edit-customer-form ul li {
  position: relative;
  display: block;
  min-height: 30px;
}
.edit-customer-form ul a {
  color: #0000ff;
}
.edit-customer-form ul table.wrapper-table {
  width: 100%;
  height: 32px;
}
.edit-customer-form ul table.wrapper-table tr td.close-column,
.edit-customer-form ul table.wrapper-table tr td.add-column {
  width: 18px;
}
.edit-customer-form ul table.wrapper-table tr td {
  min-width: 18px;
  vertical-align: middle;
}
.edit-customer-title {
  background: #f6f6f6;
}
.edit-customer-title h4 {
  font-size: 18px;
  margin: 0;
}
.edit-customer-title .btn {
  font-size: 16px;
  padding: 2px 4px;
  width: 60px;
  vertical-align: middle;
}
.edit-customer-title .title-content {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px 0 30px;
}
.edit-customer-title .title-row {
  position: relative;
  padding: 10px;
}
.edit-customer-title .title-row.title-info {
  text-align: left;
  font-size: 16px;
  line-height: 1.1;
}
.edit-customer-title .title-row.title-info strong {
  display: inline-block;
  vertical-align: bottom;
}
.edit-customer-title .title-row.title-info strong:after {
  content: 'NIS ';
  display: inline-block;
  vertical-align: bottom;
  font-size: 14px;
  font-weight: bold;
  padding: 2px;
}
.edit-customer-title .title-row.title-info span {
  font-weight: bold;
  display: inline-block;
  vertical-align: bottom;
}
.edit-customer-title .btn-info {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.edit-customer-panel {
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 3px 0 -3px 0;
  z-index: 99;
  background: #85c51f;
}
.edit-customer-panel ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.edit-customer-panel li {
  width: 20%;
  padding: 0 5px;
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  height: 60px;
}
.edit-customer-panel li span,
.edit-customer-panel li a {
  display: block;
  height: 100%;
  text-decoration: none;
  color: inherit;
  text-align: center;
}
.edit-customer-panel li .ico-holder {
  margin-bottom: 5px;
  height: 35px;
  text-align: center;
}
.edit-customer-panel li .ico-holder img {
  max-width: 100%;
  height: auto;
}
.edit-customer-wrap {
  padding-bottom: 75px;
}
.view-title {
  font-size: 22px;
  margin-bottom: 10px;
}
.view-btn {
  left: auto;
  right: 10px;
}
.view-doc-section .document-description {
  background: #0a69b1;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.view-doc-section .document-description h5 {
  font-size: 18px;
  margin: 0;
}
.view-doc-inner {
  background: #0d83dd;
  padding: 15px 0;
  color: #fff;
  overflow: hidden;
}
.view-doc-inner .hold-left {
  float: left;
  width: 130px;
  padding: 0 15px;
  border-right: 1px solid #fff;
}
.view-doc-inner .hold-right {
  overflow: hidden;
  padding: 0 15px;
  font-size: 14px;
}
.view-doc-inner .hold-right h4 {
  font-size: 20px;
  margin: 0 0 15px;
}
.view-doc-inner .hold-right dl {
  margin: 0 0 10px;
  display: block;
}
.view-doc-inner .hold-right dl dt {
  margin: 0;
  padding: 0;
  font-weight: bold;
  display: block;
  vertical-align: middle;
}
.view-doc-inner .hold-right dl dd {
  margin: 0;
  overflow: hidden;
  padding: 0 4px;
  display: block;
  vertical-align: middle;
}
.status-hold {
  text-align: center;
  padding-top: 35px;
}
.status-hold > span {
  font-size: 12px;
}
.status-inner {
  padding: 5px;
  color: #0d83dd;
  width: 55px;
  margin: 0 auto 5px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}
.status-inner span {
  position: relative;
  z-index: 4;
}
.status-inner:after {
  content: '';
  right: 3px;
  bottom: 12px;
  width: 40px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid #fff;
  position: absolute;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
}
.view-general-info {
  background: #f6f6f6;
}
.view-table {
  width: 100%;
}
.view-table tbody {
  font-size: 14px;
}
.view-table tr:hover td {
  background: #ededed;
}
.view-table td {
  padding: 5px 15px;
}
.view-table td + td {
  width: 50%;
}
.view-table tr:last-of-type td {
  font-weight: bold;
  font-size: 16px;
}
.view-add-info {
  font-size: 18px;
  background: #6eb5eb;
  color: #fff;
  padding: 10px 15px;
}
.view-add-info > table {
  width: 100%;
}
.view-add-info > table th,
.view-add-info > table td {
  text-align: right;
}
.view-add-info p {
  font-weight: bold;
  margin: 0;
}
.view-add-info span {
  font-size: 14px;
}
.contact-us-holder {
  padding: 0 15px 20px;
}
.contact-us-holder .contact-us-title {
  text-align: center;
  margin-bottom: 20px;
}
.contact-us-holder h1 {
  font-size: 24px;
  margin: 0;
}
.contact-us-info {
  padding: 10px 0;
}
.contact-us-info dl {
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.contact-us-info dd {
  overflow: hidden;
  clear: left;
  padding: 0 5px;
  margin: 0 0 4px;
}
.contact-us-info dt {
  font-weight: bold;
  float: right;
  padding: 0;
  margin: 0 0 4px;
}
.contact-us-info a {
  color: inherit;
}
.contact-us-form {
  padding: 20px;
  border: 1px solid #CCC;
  border-radius: 9px;
  position: relative;
  min-height: 100px;
  margin-bottom: 15px;
}
.contact-us-form:after {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  right: 15px;
  background: url('../../../assets/img/shadow_right.png');
}
.contact-us-form:before {
  content: "";
  width: 157px;
  height: 8px;
  position: absolute;
  top: 100%;
  left: 15px;
  background: url('../../../assets/img/shadow_left.png');
}
.contact-us-form label {
  display: block;
  margin-bottom: 5px;
}
.contact-us-form .form-group {
  margin-bottom: 15px;
}
.contact-us-form .form-control {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  line-height: inherit;
  color: #222222;
  background: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.contact-us-form textarea {
  resize: vertical;
  min-height: 90px;
}
.contact-us-form .btn {
  position: relative;
  font-size: 14px;
  padding: 10px 25px;
}
.contact-us-form .btn:after {
  position: absolute;
  font: normal normal normal 16px/1 FontAwesome;
  content: "\f067";
  font-weight: 700;
  color: #cee8a5;
  right: 6px;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
