.home-screen-block {

    //height: 100%;
    //table {
    //    width: 100%;
    //    height: 100%;
    //    td {
    //        vertical-align: middle;
    //    }
    //}
    //text-align: center;
    //.logo {
    //    width: 248px;
    //}

    position: relative;
    min-height: 100vh;

    .logo-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        img {
            max-width: 100%;
            height: auto;
        }
    }
}
