.my-documents-filter {
    position: relative;
    padding: 15px 80px;
    background: #f6f6f6;
    text-align: center;
    min-height: 45px;

    .btn-holder {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        width: 70px;
        padding: 0 5px;

        .btn {

            color: #fff;
            border-radius: 6px;
            width: 100%;
            background: #6eb5eb;
            border:1px solid #3c8fce;
            font-size: 14px;
            line-height: 1;
            text-align: center;
            outline: none;
            padding: 5px;
        }
    }

    .result-box {
        overflow: hidden;
        padding-left: 10px;

        span {
            font-weight: bold;
        }

        p {
            margin: 0;
        }

    }
}

.my-documents-title {
    text-align: center;
    margin-bottom: 10px;

    h1 {
        font-size: 24px;
        margin: 0;
    }
}

.my-documents-items {
    background: #0d83dd;
    padding: 10px;
    overflow: hidden;
}

.document-item {
    position: relative;
    margin-bottom: 15px;
    transition: margin .2s linear;
    -webkit-transition: margin .2s linear;
    -moz-transition: margin .2s linear;
    -o-transition: margin .2s linear;

    &.option-active {
        margin-left: 190px;
        margin-right: -190px;
    }

    &.inactive-item {

       .img-holder {
           .document-img-inactive {
               display: block;
           }
       }

        .document-label {
            background: #9f1e00;
        }
    }

    &.active-item {

        .img-holder {

            .document-img-active {
                display: block;
            }
        }

    }
}

.document-info {

    &.has-shadow {

        &:after {
            width: 110px;
            height: 7px;
            background: url('../../../assets/img/mobile/img-shadow-right-document.png') no-repeat;
        }

        &:before {
            width: 110px;
            height: 7px;
            background: url('../../../assets/img/mobile/img-shadow-left-document.png') no-repeat;
        }
    }
}

.document-info-inner {
    background: #fff;
    position: relative;
    z-index: 3;
    padding: 10px 60px 10px 47px;
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: 8px;
        height: 8px;
        border-top: 2px solid #0d83dd;
        border-right: 2px solid #0d83dd;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }

    //&:after {
    //    content: '';
    //    position: absolute;
    //    left: 8px;
    //    right: 8px;
    //    top: 100%;
    //    height: 7px;
    //    background: url('../../../assets/img/mobile/');
    //}

    .price-container{
        text-align: center;
        height: 30px;
    }

    .price {
        padding: 5px;
        font-size: 18px;
        color: #6eb5eb;
        font-weight: bold;
        border: 1px solid #6eb5eb;
        max-width: 160px;
        height: 100%;
        display: inline-block;

    }
    .details-container{
        width: 100%;
        white-space: nowrap;

        > span{
            display: inline-block;
            margin-left: 10px;
        }
    }
    p {
        margin: 0 0 2px;
    }

    .date {
        display: block;
    }

    h4 {
        font-size: 16px;
        margin: 0 0 2px;
    }

    .img-holder {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        img {
            display: none;
        }

    }
}

.document-options {
    overflow: hidden;
    width: 200px;
    position: absolute;
    top: 0;
    padding: 0 5px;
    right: 100%;

    .btn {
        width: 85px;
        border-radius: 8px;
        border: none;
        background: #0a69b1;
        color: #fff;
        text-align: center;
        float: left;
        margin: 0 5px;
        padding: 22px 10px;
        font-size: 14px;
        font-weight: 500;

        span {
            display: block;
            padding: 2px;
        }
    }
}

.document-label {
    position: absolute;
    width: 80px;
    background: #85c51f;
    color: #fff;
    text-align: center;
    font-weight: bold;
    left: -22px;
    top: 8px;
    font-size: 14px;
    line-height: 1;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    padding: 5px;
}

.my-documents-filter-popup {
    background: #0d83dd;
    position: fixed;
    top: 0;
    left: -270px;
    bottom: 0;
    width: 270px;
    z-index: 9999;
    //transform: translateX(-100%);
    //-webkit-transform: translateX(-100%);
    //-moz-transform: translateX(-100%);
    //-ms-transform: translateX(-100%);
    //-o-transform: translateX(-100%);
    transition: left .2s linear;
    -webkit-transition: left .2s linear;
    -moz-transition: left .2s linear;
    -o-transition: left .2s linear;

    &.filter-popup-active {
        //transform: translateX(0);
        //-webkit-transform: translateX(0);
        //-moz-transform: translateX(0);
        //-ms-transform: translateX(0);
        //-o-transform: translateX(0);
        left: 0;


        .popup-overlay {
            opacity: .8;
            visibility: visible;
        }
    }

    .popup-overlay {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 999px;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s linear;
        -webkit-transition: opacity .2s linear;
        -moz-transition: opacity .2s linear;
        -o-transition: opacity .2s linear;
    }

    .filter-popup-wrap {
        overflow-y: auto;
        max-height: 100vh;
        padding-bottom: 50px;
    }

    .filter-row {
        background: #fff;
        border-radius: 8px;
        margin-bottom: 15px;

        &.filter-row-active {

            .filter-panel {
                font-weight: bold;
                background: #f6f6f6;

                .filter-icon {
                    background: #f90d0d;

                    &:after {
                        height: 0;
                    }
                }
            }
        }

        &.has-shadow {

            &:after {
                width: 110px;
                height: 7px;
                background: url('../../../assets/img/mobile/img-shadow-right-document.png') no-repeat;
            }

            &:before {
                width: 110px;
                height: 7px;
                background: url('../../../assets/img/mobile/img-shadow-left-document.png') no-repeat;
            }
        }
    }

    .filter-slide {
        display: none;
    }

    .filter-header {
        background: #0a69b1;
        padding: 10px 65px;
        color: #fff;
        position: relative;
        text-align: center;

        h5 {
            text-align: center;
            margin: 0;
            font-size: 20px;
        }

        a {
            color: inherit;
        }

        .btn-finish {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            border: none;
            background: none;
            color: #fff;
            text-align: center;
            outline: none;

            &:after {
                margin: 0 2px;
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 7px;
                height: 7px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
            }
        }

        .btn-clear {
            outline: none;
            text-decoration: none;
            position: absolute;
            top: 50%;
            background: none;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            right: 10px;
            color: #fff;
            border: none;

        }
    }

    .filter-body {
        padding: 20px 20px;
    }

    .filter-panel {
        padding: 13px 10px 13px 40px;
        border-radius: 8px 8px 0 0;
        position: relative;
        transition: background .2s linear;
        -webkit-transition: background .2s linear;
        -moz-transition: background .2s linear;
        -o-transition: background .2s linear;

        .filter-icon {
            width: 18px;
            height: 18px;
            position: absolute;
            border-radius: 50%;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            background: #85c51f;

            &:after,
            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 2px;
                top: 50%;
                left: 50%;
                background: #fff;
                transform: translate(-50%,-50%);
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
                transition: height .2s linear;
                -webkit-transition: height .2s linear;
                -moz-transition: height .2s linear;
                -o-transition: height .2s linear;
            }

            &:after {
                width: 2px;
                height: 10px;
            }
        }

        h6 {
            display: inline-block;
            font-size: 18px;
            margin: 0;
        }
    }

    .filter-inner {
        font-size: 0;
        line-height: 0;
        padding: 20px 15px 10px;

        &.filter-inner-checkbox {
            margin: 0 -10px;
        }

        .filter-col {
            padding: 0 10px;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 1.2;
            display: inline-block;
            vertical-align: top;
            width: 50%;
        }
    }

    .checkbox-wrap {

        .custom-label {
            overflow: hidden;
            padding-right: 3px;
            width: calc(~'100% - 16px');
            display: block;
        }

        .custom-checkbox {
            float: right;
            border-color: #ccc;
            background: #f6f6f6;

            &:before {
                border-color: #575756;
            }
        }

        input[type="checkbox"] {
            position: absolute;
            width: 1px;
            height: 1px;
            visibility: hidden;
            opacity: 0;
        }
    }

    .amount-wrap {
        font-size: 14px;
        line-height: 1.2;
        text-align: center;

        span {
            display: inline-block;
            vertical-align: middle;
            padding: 0 2px;
        }

        .form-control {
            display: inline-block;
            vertical-align: middle;
            width: 60px;
            height: 32px;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 5px;
            border: 1px solid #ccc;
            border-radius: 6px;
        }
    }

    .select-holder {
        border: 1px solid #ccc;
        border-radius: 6px;
        font-size: 14px;
        line-height: 1.2;
        overflow: hidden;

        .form-control {
            width: 100%;
            border: none;
            height: 32px;
            font-size: 14px;
            line-height: 16px;
            padding-top: 8px;
            padding-bottom: 8px;

        }


    }

    .filter-total {
        color: #fff;
        padding: 5px 0;
        border-top: 1px solid #fff;
        font-size: 13px;
        text-align: left;

        p {
            margin: 0;
        }
    }
}

.popup-document {
    font: 15px/22px Arial, Helvetica, sans-serif;
    color: #3E3E3E;

    .form-group {
        overflow: hidden;
        margin: 0 0 20px;
    }

    .form-control {
        width: 100%;
        display: block;
        padding: 4px 8px;
        border-radius: 6px;
        background: #f6f6f6;
        border: 1px solid #cfcfcf;
        outline: none;
        box-shadow: none;
        height: 32px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        .placeholder({
            color: #b2b2b2;
        })
    }

    label {

        &:before {
            content: '*';
            color: #FD001C;
            display: inline-block;
            vertical-align: top;
            padding: 0 4px;
        }
    }

    .label-holder {
        float: right;
        width: 50%;
        padding: 5px 0 5px 10px;
    }

    .input-holder {
        float: right;
        width: 50%;
    }

    textarea {
        min-height: 80px;
        resize: vertical;
    }

    .btn-holder {
        text-align: center;
        padding: 40px 0 0;
    }
}

.modal-footer {

    .btn-holder {
        padding: 0;
    }
}