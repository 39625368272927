.bootbox.modal{

    position: fixed;

    top:0;
    left:0;
    z-index: 999;
    display: none;
    height: 100%;
    width: 100%;


    .modal-dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        z-index: 1100;
        border-radius: 8px;
        overflow: hidden;
        background: white;;
    }

    .modal-backdrop{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #fff;
    }
}

.modal.yellow-modal {

    transition: opacity .2s linear, visibility .2s linear;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    .modal-dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        z-index: 999;
        width: 300px;
        border-radius: 8px;
        overflow: hidden;
        background: #d9ffb2;;
    }

    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }

    &.active-popup {
        opacity: 1;
        visibility: visible;
    }

    .popup-title {
        padding: 10px;
        background: #ffff99;

        h3 {
            font-size: 18px;
            margin: 0;
        }
    }

    .modal-body {
        padding: 10px;
    }

    .has-error {

        .form-control {
            border: 1px solid #ed1c24;
        }

    }
    .form-group {
        margin-bottom: 10px;
    }

    .form-control {
        width: 100%;
        border: none;
        padding: 8px;
        height: 32px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;

    }

    .btn-holder {
        text-align: left;
    }

    .close-btn {
        position: absolute;
        left: 7px;
        top: 7px;
        background-image: url("../../../assets/img/mobile/ico_close.png")
    }
}

.overlay-popup.modal {

    .modal-backdrop{
        z-index: 999;
    }

    .overlay-modal-header {
        position: relative;
        padding: 20px 40px;
        background: #f6f6f6;

        .btn-info {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
        }

        h2 {
            font-size: 22px;
            margin: 0;
        }

        .close-btn {
            position: absolute;
            top: 7px;
            left: 7px;

            &:after,
            &:before {
                background: #b2b2b2;
            }
        }
    }

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    display: none;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .modal-dialog {
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 99999;
        position: absolute;
    }

    .edit-form {
        background: #fff;
        padding-bottom: 20px;
    }

}

/* Modal styles*/
.common-popup.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 999999;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    outline: none;

    .modal-header {
        height: 0;

        * {
            margin: 0;
        }
    }

    .modal-dialog {
        z-index: 1050;
    }

    .close {
        text-decoration: none;
        color: #fff;
        position: absolute;
        top: 8px;
        left: 8px;
        width: 18px;
        height: 18px;
        display: block;
        outline: none;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 2px;
            transform: translate(-50%, -50%) rotate(-45deg);
            -webkit-transform: translate(50%,-50%) rotate(-45deg);
            -moz-transform: translate(50%,-50%) rotate(-45deg);
            -ms-transform: translate(50%,-50%) rotate(-45deg);
            -o-transform: translate(50%,-50%) rotate(-45deg);
            background: #fff;
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
            -webkit-transform: translate(50%,-50%) rotate(45deg);
            -moz-transform: translate(50%,-50%) rotate(45deg);
            -ms-transform: translate(50%,-50%) rotate(45deg);
            -o-transform: translate(50%,-50%) rotate(45deg);
        }

        .fa {
            display:  none;
        }
    }

    .modal-inner {
        background: #0d83dd;
        border-radius: 10px;
        padding: 35px 25px 10px;
        text-align: center;
        position: relative;
        z-index: 1000;
        max-height: 92vh;
        overflow-y: auto;
        width: 290px;
    }

    .modal-content {
        color: #fff;
        text-align: center;
        font-size: 20px;

        p {
            margin: 0 0 20px;
        }
    }

    .modal-image {
        margin-bottom: 20px;
    }

    .add-info {
        padding: 5px 0;
        display: block;
        font-size: 13px;
    }
}


/*popup document styles*/
.popup-document {

    .modal-dialog {
        border-radius: 10px;
        border: 7px solid #DCECFB;
        overflow: hidden;
        width: 90%;
        z-index: 9999;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.5);

        &:after {
            content: '';
            width: 60px;
            height: 60px;
            background: #DCECFB;
            position: absolute;
            top: 0;
            right: 0;
            -webkit-transform: translate(50%,-50%) rotate(45deg);
            -moz-transform: translate(50%,-50%) rotate(45deg);
            -ms-transform: translate(50%,-50%) rotate(45deg);
            -o-transform: translate(50%,-50%) rotate(45deg);
            transform: translate(50%,-50%) rotate(45deg);
        }
    }

    .modal-header {
        font-size: 24px;
        color: #000;
        padding: 30px 40px;
        border-bottom: 7px solid #DCECFB;
        position: relative;

        .close {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 20px;
            color: #6FB5E8;
            z-index: 2;
        }
    }

    h4 {
        margin: 0;
    }

    .modal-body {
        padding: 25px 20px;
        max-height: 70vh;
        overflow: auto;
    }

    .modal-footer {
        border-top: 7px solid #DCECFB;
        padding: 15px 40px;
        text-align: center;
    }
}

.modal-backdrop {
    position: fixed;
    top: -9999px;
    left: -9999px;
    bottom: -9999px;
    right: -9999px;
    background: #fff;
    opacity: 0;
    z-index: 800;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;

    &.in {
        opacity: 0.8;
    }
}