.header-hide {
    display: none;
}

.header-holder {
    padding: 10px;
    position: relative;

    .header-search {
        overflow: hidden;
        font-size: 17px;
        line-height: 20px;

        .form-group {
            position: relative;
            overflow: hidden;
        }

        .form-control {
            width: 100%;
            display: block;
            height: 30px;
            padding: 5px 15px 5px 40px;
            background: #E6F4FC;
            border-radius: 6px;
            box-shadow: none;
            border: none;
            outline: none;
            color: #6FB5EA;

            .placeholder({
                color: #6FB5EA;
                text-align: center;
                text-transform: uppercase;
            })
        }

        .search-button {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: none;
            width: 30px;
            outline: none;
            border: none;
            border-radius: 6px;
            color: #6FB5EA;

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
            }
        }
    }

    .main-nav {
        float: right;
        width: 40px;
    }

    .menu-opener {
        position: relative;
        width: 32px;
        height: 32px;
        outline: none;
        background: none;
        border: none;


        &:after,
        &:before,
        span {
            top: 12px;
            position: absolute;
            height: 4px;
            width: 24px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            background: #1985d8;
        }

        &:before {
            content: '';
            top: 4px;
        }

        &:after {
            content: '';
            top: 20px;
        }
    }
}

.nav-drop {
    width: 220px;
    background: #1985d8;
    font-size: 17px;
    padding-bottom: 50px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 100%;
    color: #fff;

    .nav-title {
        display: block;
        background: #166bad;
        padding: 15px;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 1px solid #fff;
    }


    ul {
        margin: 0;
        padding: 0;
        list-style: none;


        li {
            border-bottom: 1px solid #fff;
            
            &.active {
                background: #166bad;
            }
        }

        a {
            padding: 8px 15px;
            color: inherit;
            display: block;
            text-decoration: none;
        }
    }
}

.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #fff;
    z-index: 9999;

    .modal-header{
        display: none;
    }

    .close-btn {
        left: 10px;
        position: absolute;
        background-image: url(../../../../assets/img/mobile/ico_close.png);
    }
    .modal-dialog{
        overflow: auto!important;

    }
}

.search-box {
    overflow: hidden;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 30px;

    .form-group {
        position: relative;
        overflow: hidden;
    }

    .form-control {
        width: 100%;
        display: block;
        height: 30px;
        padding: 5px 40px 5px 10px;
        background: #E6F4FC;
        border-radius: 6px;
        box-shadow: none;
        border: none;
        outline: none;
        color: #6FB5EA;

        .placeholder({
            color: #6FB5EA;
            text-align: center;
            text-transform: uppercase;
        })
    }

    .search-button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: none;
        width: 30px;
        outline: none;
        border: none;
        border-radius: 6px;
        color: #6FB5EA;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

.search-result-row {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 7px 0 7px 20px;
        position: relative;
        color: #c6c6c6;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            border-right: 7px solid #0d83dd;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
        }

        b {
            font-weight: bold;
            color: #000;
        }
    }
}

.search-autocomplete {
    padding: 35px 10px 30px;
}

.search-result-title {
    background: #f6f6f6;
    padding: 5px 30px 5px 5px;
    position: relative;
    font-weight: bold;
    color: #000;

    .ico-holder {
        position: absolute;
        right: 5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 21px;
        line-height: 1;

        img {
            display: block;
        }
    }
}