.documents-holder {
    padding: 10px 10px 70px;
}

.documents-title {
    position: relative;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;


    .documents-title-inner {
        background: #0d83dd;
        border-radius: 6px;
        position: relative;
        z-index: 2;
        padding: 20px;
    }

    h1 {
        font-size: 24px;
        margin: 0;
    }
}

.documents-section {
    font-size: 0;
    line-height: 0;
    text-align: center;

    &.sup-active {
        position: relative;
        //z-index: 30;

        &:after {
            content: '';
            position: fixed;
            top: -9999px;
            left: -999px;
            right: -999px;
            z-index: 999;
            bottom: -9999px;
            background: #fff;
            opacity: 0.8;
            animation-name: fade-out-animate;
            animation-duration: 1s;
            animation-delay: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: both;
            -webkit-animation-name: fade-out-animate;
            -webkit-animation-duration: 1s;
            -webkit-animation-delay: 1s;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-fill-mode: both;
        }

        .sup-img {
            .documents-title{
                position: fixed;
                top: 0;
                left: 0;
                width: 354px;
                margin-top: 76px;
                height: 67px;
                margin-left: 10px;
                .documents-title-inner{
                    height: 100%;
                    padding: 33px;
                }

            }


            display: block;
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 9999;
            width: 325px;
            animation-name: fade-out-animate;
            animation-duration: 1.1s;
            animation-delay: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: both;
            -webkit-animation-name: fade-out-animate;
            -webkit-animation-duration: 1.1s;
            -webkit-animation-delay: 1s;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-fill-mode: both;
            img {
                display: block;
                width: 100%;
            }
        }
    }

    .document-item {
        font-size: 16px;
        line-height: 1.2;
        width: 50%;
        padding: 10px 15px;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;

        a {
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            color: inherit;
            outline: none;
        }
    }

    .img-holder {
        margin-bottom: 2px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    p {
        margin: 0;
    }

    .sup-img {
        display: none;
    }
}

.documents-number {
    position: fixed;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 40;
    font-size: 13px;
    line-height: 1;
    border: 1px solid #659c17;
    background: #85c51f;
    padding: 26px 0;
    color: #fff;
    outline: none;
    margin: 3px 0 -3px;

    .img-holder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        right: 12px;
    }

    div {
        position: relative;
        padding: 0 70px;
        display: inline-block;
        vertical-align: top;
    }
}

@keyframes fade-out-animate {
    0% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes fade-out-animate {
    0% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}