.contact-us-holder {
    padding: 0 15px 20px;

    .contact-us-title {
        text-align: center;
        margin-bottom: 20px;
    }

    h1 {
        font-size: 24px;
        margin: 0;
    }
}

.contact-us-info {
    padding: 10px 0;

    dl {
        overflow: hidden;
        padding: 0;
        margin: 0;
    }

    dd {
        overflow: hidden;
        clear: left;
        padding: 0 5px;
        margin: 0 0 4px;
    }

    dt {
        font-weight: bold;
        float: right;
        padding: 0;
        margin: 0 0 4px;
    }

    a {
        color: inherit;
    }
}

.contact-us-form {
    padding: 20px;
    border: 1px solid #CCC;
    border-radius: 9px;
    position: relative;
    min-height: 100px;
    margin-bottom: 15px;

    &:after {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        right: 15px;
        background: url('../../../assets/img/shadow_right.png');
    }

    &:before {
        content: "";
        width: 157px;
        height: 8px;
        position: absolute;
        top: 100%;
        left: 15px;
        background: url('../../../assets/img/shadow_left.png');
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-control {
        font-size: 14px;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        line-height: inherit;
        color: #222222;
        background: #f6f6f6;
        border: 1px solid #ccc;
        border-radius: 3px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    textarea {
        resize: vertical;
        min-height: 90px;
    }

    .btn {
        position: relative;
        font-size: 14px;
        padding: 10px 25px;

        &:after {
            position: absolute;
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f067";
            font-weight: 700;
            color: #cee8a5;
            right: 6px;
            top: 50%;
            display: block;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}