.view-title {
    font-size: 22px;
    margin-bottom: 10px;
}

.view-btn {
    left: auto;
    right: 10px;
}

.view-doc-section {

    .document-description {
        background: #0a69b1;
        color: #fff;
        padding: 10px;
        text-align: center;

        h5 {
            font-size: 18px;
            margin: 0;
        }
    }
}

.view-doc-inner {
    background: #0d83dd;
    padding: 15px 0;
    color: #fff;
    overflow: hidden;

    .hold-left {
        float: left;
        width: 130px;
        padding: 0 15px;
        border-right: 1px solid #fff;
    }

    .hold-right {
        overflow: hidden;
        padding: 0 15px;
        font-size: 14px;

        h4 {
            font-size: 20px;
            margin: 0 0 15px;
        }

        dl {
            margin: 0 0 10px;
            display: block;

            dt {
                margin: 0;
                padding: 0;
                font-weight: bold;
                display: block;
                vertical-align: middle;
            }

            dd {
                margin: 0;
                overflow: hidden;
                padding: 0 4px;
                display: block;
                vertical-align: middle;

            }
        }
    }
}

.status-hold {
    text-align: center;
    padding-top: 35px;

    > span {
        font-size: 12px;
    }
}

.status-inner {
    padding: 5px;
    color: #0d83dd;
    width: 55px;
    margin: 0 auto 5px;
    border-radius: 5px;
    background: #fff;
    position: relative;

    span {
        position: relative;
        z-index: 4;
    }

    &:after {
        content: '';
        right: 3px;
        bottom: 12px;
        width: 40px;
        height: 25px;
        border-radius: 5px;
        border: 2px solid #fff;
        position: absolute;
        text-align: center;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 2;
    }
}

.view-general-info {
    background: #f6f6f6;
}

.view-table {
    width: 100%;

    tbody {
        font-size: 14px;
    }
    
    tr {

        &:hover {
            
            td {
                background: #ededed;
            }
        }
    }

    td {
        padding: 5px 15px;

        + td {
            width: 50%;
        }
    }

    tr:last-of-type td {
        font-weight: bold;
        font-size: 16px;
    }
}

.view-add-info {

    >table{
        width: 100%;
        th,td{
            text-align: right;
        }
    }
    font-size: 18px;
    background: #6eb5eb;
    color: #fff;
    padding: 10px 15px;

    p {
        font-weight: bold;
        margin: 0;
    }

    span {
        font-size: 14px;
    }
}